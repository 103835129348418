import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";

type DropDownProps = {
  options: string[];
  defaultSelected: string;
  onSelect: (value: string) => void;
  dropdownColor?: string;
  textColor?: string;
};

const CustomDropdown: React.FC<DropDownProps> = ({
  options,
  defaultSelected,
  onSelect,
  dropdownColor = "#fff",
  textColor = "#000",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(defaultSelected);

  const handleSelect = (value: string) => {
    setSelected(value);
    onSelect(value);
    setIsOpen(false);
  };

  return (
    <div
      className="custom-dropdown"
      style={{ backgroundColor: dropdownColor }}
    >
      <div
        className="dropdown-header"
        onClick={() => setIsOpen((prev) => !prev)}
        style={{ color: textColor }}
      >
        <span style={{ color: textColor }}>{selected}</span>
        <FaChevronDown className="dropdown-icon" style={{ color: textColor }} />
      </div>
      {isOpen && (
        <ul
          className="dropdown-list"
          style={{ backgroundColor: dropdownColor }}
        >
          {options.map((option) => (
            <li
              key={option}
              className="dropdown-items"
              onClick={() => handleSelect(option)}
              style={{ color: textColor }} // Apply text color to dropdown items
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
