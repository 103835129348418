import React, { useState } from "react";
import { Modal } from "reactstrap";

import SectionHeader from "../section-header";

import Icon from "../../assets/svg";
import Button from "../button";

type Props = {
  isOpen: boolean;
  close: any;
};

const CheckCampaignMessageModal = ({ isOpen, close }: Props) => {
  const handleContinue = () => {
    close();
  };

  return (
    <Modal className="check_campaign_message_modal" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
          leftSide={<div className="title_box">CHECK CAMPAIGN MESSAGE</div>}
          rightSide={
            <>
              <div className="icon" onClick={close}>
                <Icon name="close" />
              </div>
            </>
          }
        />
      </div>

      <div className="modal_body">
        <div className="check_campaign_message">
          <p>
            You cannot send out this campaign without changing the default image
            on the campaign template. Once you change the image, you will be
            able to start the campaign.
          </p>

          <div className="button_box">
            <Button onClick={handleContinue} text="Continue" />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CheckCampaignMessageModal;
