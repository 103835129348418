import { useState } from "react";
import { Modal } from "reactstrap";

import SectionHeader from "../section-header";
import Button from "../button";

import GrapeJsEditor from "../grapejs-editor";

type Props = {
  isOpen: boolean;
  close: any;
  msgContent: string;
  businessSlug: string;
  setMsgContent: React.Dispatch<React.SetStateAction<string>>;
  setSaveEditedTemplate: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditorModal = ({
  isOpen,
  close,
  msgContent,
  businessSlug,
  setMsgContent,
  setSaveEditedTemplate,
}: Props) => {
  const [grapejsEditor, setGrapejsEditor] = useState<any>();
  const onReady = (editor: any) => setGrapejsEditor(editor);

  // if (!grapejsEditor) throw new Error("Grapesjs editor not mounted!");

  const saveMessageChanges = () => {
    if (grapejsEditor) {
      console.log("Grapejs html:", grapejsEditor.getHtml());
      setMsgContent(grapejsEditor.getHtml());
      setSaveEditedTemplate(true);
      close();
    }
  };

  return (
    <Modal className="editor_modal template_large" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
          leftSide={<div className="title_box">EDIT CAMPAIGN MESSAGE</div>}
          rightSide={
            <>
              <div className="icon" onClick={close}>
                {/* <Icon name="close" /> */}
                <Button
                  className="btn_secondary"
                  text="Close"
                  onClick={close}
                />
                <Button
                  text="Save Changes"
                  btnStyle={{ marginLeft: "1rem" }}
                  onClick={saveMessageChanges}
                />
              </div>
            </>
          }
        />
      </div>

      <div className="modal_body">
        <div className="editor_container">
          <GrapeJsEditor
            content={msgContent}
            onReady={onReady}
            businessSlug={businessSlug}
          />
        </div>
        <div className="btn-box">
          <Button className="btn_secondary" text="Close" onClick={close} />
          <Button text="Save Changes" onClick={saveMessageChanges} />
        </div>
      </div>
    </Modal>
  );
};
export default EditorModal;
