import { useEffect, useState } from "react";
import { startCountdown } from "../utils/functions";

interface CountdownDisplay {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

type Props = {
  name: string;
  daysToCountdown: number;
};

const ComingSoon = ({ name, daysToCountdown }: Props) => {
  const [countdown, setCountdown] = useState<CountdownDisplay>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // For this example, let's count down from 10 days

  useEffect(() => {
    const LOCAL_STORAGE_KEY = "myCountdownEndTime";

    // 1. Check if we already have an end time in localStorage
    let storedEndTime = localStorage.getItem(LOCAL_STORAGE_KEY);

    // 2. If not, create one by adding `daysToCountdown` days to current time
    if (!storedEndTime) {
      const now = Date.now();
      const endTime = now + daysToCountdown * 24 * 60 * 60 * 1000;
      storedEndTime = endTime.toString();
      localStorage.setItem(LOCAL_STORAGE_KEY, storedEndTime);
    }

    const endTime = parseInt(storedEndTime, 10);

    // A helper function to update the countdown each second
    const updateCountdown = () => {
      const now = Date.now();
      const diff = endTime - now;

      if (diff <= 0) {
        // If we've passed the end time, clear the interval and show "0"
        clearInterval(intervalId);
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return;
      }

      // Calculate remaining days/hours/minutes/seconds
      const totalSeconds = Math.floor(diff / 1000);
      const d = Math.floor(totalSeconds / (3600 * 24));
      const h = Math.floor((totalSeconds % (3600 * 24)) / 3600);
      const m = Math.floor((totalSeconds % 3600) / 60);
      const s = totalSeconds % 60;

      setCountdown({ days: d, hours: h, minutes: m, seconds: s });
    };

    // Update once immediately, then every second
    updateCountdown();
    const intervalId = setInterval(updateCountdown, 1000);

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, [daysToCountdown]);

  return (
    <div className="coming-soon-container">
      <div className="inner">
        <p className="heading">Coming Soon...</p>
        <p className="sub-text">
          We will be ready to launch {name} in{" "}
          <span>
            {countdown.days} days : {countdown.hours} hours :{" "}
            {countdown.minutes} minutes : {countdown.seconds}seconds
          </span>
        </p>
      </div>
    </div>
  );
};

export default ComingSoon;
