import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { ProtectedRoute } from "./components/protected-route";
import DashboardPages from "./dashboard/dashboard";

import SignInPage from "./pages/auth/sign-in";
import SignUpPage from "./pages/auth/sign-up";
import ForgotPasswordPage from "./pages/auth/forgot-password";
import ResetPasswordPage from "./pages/auth/reset-password";

import Alert from "./components/alert";
import "react-datepicker/dist/react-datepicker.css";
import ShopifySignUpPage from "./pages/auth/shopify-signup";

const App = () => {
  const { alert } = useSelector((state: any) => state.componentsSlice);

  const [currentVersion] = useState(process.env.REACT_APP_VERSION);

  useEffect(() => {
    const interval = setInterval(() => {
      fetch("/version.json", { cache: "no-store" })
        .then((response) => response.json())
        .then((data) => {
          console.log("version-json", data.version);
          console.log("version-env", currentVersion);
          if (data.version !== currentVersion) {
            // Option 1: Prompt the user
            if (window.confirm("New version available. Reload now?")) {
              window.location.reload();
            }
            // Option 2: Automatically reload
            // window.location.reload();
          }
        })
        .catch((err) => console.error("Error checking version:", err));
    }, 60000); // Check every 60 seconds

    //----

    return () => clearInterval(interval);
  }, [currentVersion]);

  const DummyPage = () => {
    return <>Hello</>;
  };

  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={SignInPage} />
          <Route exact path="/sign-up" component={SignUpPage} />
          <Route exact path="/shopify-signup" component={ShopifySignUpPage} />
          <Route exact path="/verify" component={DummyPage} />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
          <Route exact path="/reset-password" component={ResetPasswordPage} />
          <ProtectedRoute component={DashboardPages} />
        </Switch>
      </BrowserRouter>

      {/* Other components */}
      {alert?.show && (
        <Alert className={alert?.type} text={alert?.message} close />
      )}
    </React.Fragment>
  );
};

export default App;
