import DatePicker from "react-datepicker";

import Icon from "../assets/svg";

type Props = {
  startDate?: any;
  endDate?: any;
  onChange: any;
  value?: any;
  range: boolean;
  showYearDropdown?: boolean;
  text?: string;
  placeholderText?: string;
  maxDate?: any;
  minDate?: any;
  isClearable?: boolean;
};

const DatePickerComponent = ({
  startDate,
  endDate,
  onChange,
  value,
  range,
  showYearDropdown,
  text,
  placeholderText,
  maxDate,
  minDate,
  isClearable,
  ...rest
}: Props) => {
  return (
    <div className="datepicker_container">
      {/* <Icon name="calendar" /> */}
      {startDate || endDate ? (
        <></>
      ) : text ? (
        <p className="text">{text}</p>
      ) : (
        <></>
      )}
      <DatePicker
        startDate={startDate}
        endDate={endDate}
        dateFormat="MMM dd, yyyy"
        className="date_picker_input"
        shouldCloseOnSelect={false}
        minDate={minDate}
        maxDate={maxDate}
        selectsRange={range ? true : false}
        onChange={onChange}
        value={value}
        isClearable={isClearable}
        showYearDropdown={showYearDropdown ? true : false}
        peekNextMonth={showYearDropdown ? true : false}
        showMonthDropdown={showYearDropdown ? true : false}
        dropdownMode="select"
        placeholderText={placeholderText}
        {...rest}
      />
    </div>
  );
};

export default DatePickerComponent;
