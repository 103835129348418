import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";
import { FaTimes, FaEllipsisH } from "react-icons/fa";
import PreviewModal from "./preview-template";

import { useAppDispatch } from "../../redux";
import { getKonvasCampaignTemplatesAction } from "../../redux/dashboard/campaigns-slice";
import Loader from "../loader";



type Props = {
    isOpen: boolean;
    open: (isOpen: boolean) => void;
    brandData: any;
  onSubmit: (payload: any) => void;
};

interface KonvasCampaignTemplates {
    id: number;
    title: string;
    status: string;
    description: string;
    html: string;
}



const KonvasCampaignTemplatesModal = ({ isOpen, open, brandData, onSubmit }: Props) => {
    const dispatch = useAppDispatch();
    const {
        konvasCampaignTemplates,
        isFetchingKonvasCampaignTemplates
    } = useSelector((state: any) => state.campaignsSlice);
    
    const [isPreviewOpen, setPreviewOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<KonvasCampaignTemplates | null>(null);

    console.log(isFetchingKonvasCampaignTemplates, konvasCampaignTemplates);



    useEffect(() => {
       getKonvasCampaignTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
      const getKonvasCampaignTemplates = async () => {
        await dispatch(getKonvasCampaignTemplatesAction())
    };

    const handleClose = () => {
        open(false);
    };

    const handleTemplateClick = (template: KonvasCampaignTemplates) => {
        setSelectedTemplate(template);
       localStorage.setItem('tmplt_id', template.id.toString());
        setPreviewOpen(true);
    };

    const handlePreviewClose = () => {
        setPreviewOpen(false);
        setSelectedTemplate(null);
    };
    const handleGoBack = () => {
        setPreviewOpen(false);
    };

    return (
        <>
            <Modal isOpen={isOpen} className="konvas-modal">
                <div className="template-modal_container">
                    <div className="modal-header">
                        <div>
                            <h3 className="modal-title">Konvas AI Templates</h3>
                            <p>Select a template to send your campaigns</p>
                        </div>
                        <div className="action-icons">
                            <FaEllipsisH className="action-icon" />
                            <FaTimes className="close-icon" onClick={handleClose} />
                        </div>
                    </div>
                    <div className="modal-body">
                        {
                            isFetchingKonvasCampaignTemplates ? <Loader/> : konvasCampaignTemplates ? <table className="templates-table">
                            <thead>
                                <tr className="table-header-container">
                                    <th className="temp-table-head">Name</th>
                                    <th className="temp-table-head">Description</th>
                                    <th className="temp-table-head">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {konvasCampaignTemplates.map((template : KonvasCampaignTemplates) => (
                                    <tr key={template.id} onClick={() => handleTemplateClick(template)} className='template-table-row'>
                                        <td className="template-name">
                                            {/* <img src={template.image} alt={template.name} className="template-image" /> */}
                                            <span className="temp-name">{template.title}</span>
                                        </td>
                                        <td className="temp-desc">{template.description}</td>
                                        <td>
                                            <FaEllipsisH className="action-icon" />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>: <></>
                        }
                    </div>
                </div>
            </Modal>

            {selectedTemplate && (
                <PreviewModal
                    isOpen={isPreviewOpen}
                    brandData={brandData} onSubmit={onSubmit}
                    open={handlePreviewClose} templateUrl={selectedTemplate.html} goBack={handleGoBack} />
            )}
        </>
    );
};

export default KonvasCampaignTemplatesModal;
