import React from "react";

type FormFieldNameProps = {
    name: string;
    isRequired?: boolean;
    additionalText?: string;
};

const FormFieldName: React.FC<FormFieldNameProps> = ({
    name,
    isRequired = false,
    additionalText,
}) => {
    return (
        <div className="name_container">
            <p className="text_heading">
                {name}{" "}
                {additionalText && (
                    <span style={{ color: "#999", fontSize: "0.9em" }}>
                        {additionalText}
                    </span>
                )}{" "}
                {isRequired && <span style={{ color: "red" }}>*</span>}
            </p>
        </div>
    );
};

export default FormFieldName;