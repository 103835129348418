import { useState } from "react";
import { Modal } from "reactstrap";
import moment from "moment";

import Button from "../button";
import DatePickerComponent from "../datepicker";
import SectionHeader from "../section-header";

import Icon from "../../assets/svg";

type Props = {
  isOpen: boolean;
  close: any;
  isSchedulingCampaign: boolean;
  isUpdatingCampaign: boolean;
  scheduleCampaign: (start_date: string, end_date: string) => void;
  isCampaignDateChange: boolean;
};

const CampaignScheduleModal = ({
  isOpen,
  close,
  isSchedulingCampaign,
  isUpdatingCampaign,
  scheduleCampaign,
  isCampaignDateChange,
}: Props) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const handleSchedule = () => {
    const start_date = moment(startDate).format("YYYY-MM-DD h:mm:ss");
    const end_date = moment(endDate).format("YYYY-MM-DD h:mm:ss");
    scheduleCampaign(start_date, end_date);
  };

  return (
    <Modal className="campaign_schedule_modal" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
          leftSide={
            <div className="title_box">
              {isCampaignDateChange
                ? "CHANGE CAMPAIGN DATE"
                : "SCHEDULE CAMPAIGN"}
            </div>
          }
          rightSide={
            <>
              <div className="icon" onClick={close}>
                <Icon name="close" />
              </div>
            </>
          }
        />
      </div>

      <div className="modal_body">
        <p className="heading">
          {isCampaignDateChange
            ? "Change your campaign date"
            : "Schedule your campaign"}
        </p>
        <p className="sub_heading">
          {isCampaignDateChange ? "Change" : "Set"} start and end date for your
          campaign
        </p>
        <div className="datepicker">
          <DatePickerComponent
            startDate={startDate}
            endDate={endDate}
            text="Click here to select date"
            onChange={(update: any) => {
              setDateRange(update);
            }}
            // isClearable={true}
            range={true}
          />
        </div>

        <div className="btn_container">
          <Button
            text="Proceed"
            onClick={handleSchedule}
            loading={
              isCampaignDateChange ? isUpdatingCampaign : isSchedulingCampaign
            }
            disabled={
              !(startDate && endDate) ||
              (isCampaignDateChange ? isUpdatingCampaign : isSchedulingCampaign)
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default CampaignScheduleModal;
