import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../redux";
import moment from "moment";
import classNames from "classnames";
import "suneditor/dist/css/suneditor.min.css";

import CampaignDetails from "./campaign-details";
import CampaignReport from "./campaign-report";
import CampaignSchedule from "./campaign-schedule";

import PageTitle from "../../components/page-title";
import Topbar from "../../components/topbar";
import Button from "../../components/button";
import Loader from "../../components/loader";
import NoData from "../../components/no-data";
import FormInput from "../../components/form-input";
import NewCampaignModal from "../../components/modal/new-campaign-modal";
import ConfirmationModal from "../../components/modal/confirmation-modal";
import CampaignAIAgentModal from "../../components/modal/campaign-ai-agent-modal";
import CampaignRecepientModal from "../../components/modal/campaign-recepients-modal";
import CampaignScheduleModal from "../../components/modal/campaign-schedule-modal";
import SelectCampaignSenderModal from "../../components/modal/select-campaign-sender-modal";
import KlaviyoAuthModal from "../../components/modal/klaviyo-auth-modal";
import KlaviyoTemplateModal from "../../components/modal/klaviyo-template-modal";
import UpdateBrandAssetsModal from "../../components/modal/update-brand-assets-modal";

import {
  getCampaignsAction,
  getCampaignsByIdAction,
  postAddCampaignAction,
  deleteCampaignAction,
  rewriteCampaignMsgAction,
  sendCampaignEmailAction,
  postUpdateCampaignAction,
  getCampaignEmailAnalyticsAction,
  scheduleCampaignAction,
  pauseCampaignAction,
  continueCampaignAction,
  endCampaignAction,
  addCampaignForPersonalizationAction,
} from "../../redux/dashboard/campaigns-slice";
import { getBrandDataAction } from "../../redux/dashboard/brand-slice";

import Icon from "../../assets/svg";

import { APP_LIST_LAYOUT_LIST } from "../../utils/constants";
import { scheduleKlaviyoCampaignSend } from "../../redux/dashboard/klaviyo-slice";
import KonvasCampaignTemplatesModal from "../../components/modal/konvas-templates-modal";
import { convertEscapedStringToHTML } from "../../utils/functions";
import queryString from "query-string";
import CampaignRecipientLimitModal from "../../components/modal/campaign-recipient-limit-modal";
import { setAlert } from "../../redux/components/components-slice";
import CheckCampaignMessageModal from "../../components/modal/check-campaign-message-modal";

// import { dateToFromNow } from "../../utils/functions";

const listOrderOptions = [
  { label: "Most recent", value: "desc" },
  { label: "Oldest", value: "asc" },
];

const statusOptions = [
  { label: "Draft", value: "draft" },
  { label: "Scheduled", value: "scheduled" },
  { label: "Active", value: "active" },
  { label: "Completed", value: "completed" },
];

const CampaignsPage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { brandData, isFetchingBrandData } = useSelector(
    (state: any) => state.brandSlice
  );

  const { campaignId } = queryString.parse(location.search);
  const isExplicitlySearching = location.search.includes("campaignId");

  const updateUrlWithCampaign = (id: string | null) => {
    if (id) {
      history.push({
        pathname: "/campaigns",
        search: `?campaignId=${id}`,
      });
    } else {
      history.push("/campaigns");
    }
  };

  const { listLayout } = useSelector((state: any) => state.componentsSlice);
  const {
    isFetchingCampaigns,
    campaigns,
    isFetchingCampaignData,
    isDeletingCampaign,
    isEndingCampaign,
    isContinuingCampaign,
    isPausingCampaign,
    isSchedulingCampaign,
    isUpdatingCampaign,
    isStartingCampaign,
  } = useSelector((state: any) => state.campaignsSlice);

  const [campaignsData, setCampaignsData] = useState(campaigns || []);
  const [listOrder, setListOrder] = useState(listOrderOptions[0]);
  const [status, setStatus] = useState(statusOptions[0].value);
  const [selectedCampaign, setSelectedCampaign] = useState<any>(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedWritingApproach, setSelectedWritingApproach] = useState("");
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [isRewritingMsg, setIsRewritingMsg] = useState(false);
  const [msgSubject, setMsgSubject] = useState("");
  const [msgContent, setMsgContent] = useState("");
  const [isEditingMsgContent, setIsEditingMsgContent] = useState(false);
  const [analytics, setAnalytics] = useState({});
  const [newCampaignModal, setNewCampaignModal] = useState(false);
  const [updateBrandAssetsModal, setUpdateBrandAssetsModal] = useState(false);
  const [isCampaignAiAgentModalOpen, setIsCampaignAiAgentModalOpen] =
    useState(false);
  const [isCampaignRecepientModalOpen, setIsCampaignRecepientModalOpen] =
    useState(false);
  const [isCampaignScheduleModalOpen, setIsCampaignScheduleModalOpen] =
    useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isCampaignReportExpanded, setIsCampaignReportExpanded] =
    useState(false);
  const [
    initialCampaignPromptFromInsight,
    setInitialCampaignPromptFromInsight,
  ] = useState("");

  const [isSelectCampaignSenderModalOpen, setIsSelectCampaignSenderModalOpen] =
    useState(false);
  const [isKlaviyoAuthModalOpen, setIsKlaviyoAuthModalOpen] = useState(false);
  const [
    isKonvasCampaignTemplatesModalOpen,
    setIsKonvasCampaignTemplatesModalOpen,
  ] = useState(false);
  const [isKlaviyoTemplateModalOpen, setIsKlaviyoTemplateModalOpen] =
    useState(false);
  const [
    isCampaignRecipientLimitModalOpen,
    setIsCampaignRecipientLimitModalOpen,
  ] = useState(false);
  const [checkCampaignMessageModal, setCheckCampaignMessageModal] =
    useState(false);

  const [campaignSender, setCampaignSender] = useState("Konvas AI");
  const [isCampaignDateChange, setIsCampaignDateChange] = useState(false);
  const [sendTo5000Anyway, setSendTo5000Anyway] = useState(false);
  const [saveEditedTemplate, setSaveEditedTemplate] = useState(false);

  const editor = useRef(null);

  const getSunEditorInstance = (sunEditor: any) => {
    editor.current = sunEditor;
  };

  const setCampaignInitialSubjectAndContent = () => {
    setMsgSubject(
      selectedCampaign?.campaign_subject?.replace(
        "[Customer Name]",
        "{$customerName}"
      )
    );
    // setMsgContent(msgContentRevamp(selectedCampaign?.campaign_body));

    console.log(
      "selectedCampaignBody:",
      convertEscapedStringToHTML(JSON.parse(selectedCampaign?.campaign_body))
    );
    setMsgContent(
      convertEscapedStringToHTML(JSON.parse(selectedCampaign?.campaign_body))
    );
  };

  const sumUpAnalyticsKey = (arr: any, key: string) => {
    return arr?.reduce((a: any, b: any) => a + (b[key] || 0), 0);
  };
  const getCampaignAnalytics = () => {
    dispatch(getCampaignEmailAnalyticsAction(selectedCampaign.id)).then(
      (res: any) => {
        const data: any = res?.data;

        const reached = sumUpAnalyticsKey(data, "delivered");
        const delivered = sumUpAnalyticsKey(data, "delivered");
        const bounced =
          sumUpAnalyticsKey(data, "soft_bounced") +
          sumUpAnalyticsKey(data, "hard_bounced");
        const opened = sumUpAnalyticsKey(data, "opened");
        const unsubscribed = sumUpAnalyticsKey(data, "unsubscribed");
        const clicked = sumUpAnalyticsKey(data, "clicked");
        const sent = sumUpAnalyticsKey(data, "sent");
        const pending = sumUpAnalyticsKey(data, "queued") - sent;

        setAnalytics({
          reached,
          delivered,
          bounced,
          opened,
          unsubscribed,
          pending,
          clicked,
          sent,
        });
      }
    );
  };

  useEffect(() => {
    if (saveEditedTemplate) {
      updateCampaign();
    }
  }, [saveEditedTemplate]);

  useEffect(() => {
    setIsEditingMsgContent(false);
    if (selectedCampaign?.campaign_body) setCampaignInitialSubjectAndContent();

    if (selectedCampaign?.status === "active") {
      getCampaignAnalytics();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaign]);

  useEffect(() => {
    if (selectedCampaign?.ai_tones) {
      try {
        const parsedTones = JSON.parse(selectedCampaign.ai_tones);
        setSelectedTags(parsedTones.map((tag: string) => ({ tag })));
      } catch (error) {
        console.error("Error parsing AI tones:", error);
        setSelectedTags([]);
      }
    }
  }, [selectedCampaign]);

  useEffect(() => {
    setCampaignsData(campaigns?.filter((x: any) => x.status === status));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaigns, status]);

  const topBarFilters: any = [
    {
      type: "select",
      value: listOrder,
      options: listOrderOptions,
      onChange: (value: any) => setListOrder(value),
    },
  ];

  const getCampaigns = async (value?: string) => {
    // if (value === "custom") {
    //   setSegmentData([]);
    // } else {
    //   dispatch(getCampaignsAction());
    // }

    await dispatch(getCampaignsAction());
  };

  const clearState = () => {
    history.push(location.pathname, null);
  };

  useEffect(() => {
    getCampaigns();
    dispatch(getBrandDataAction());
    if (location.state) {
      setNewCampaignModal(true);
      setInitialCampaignPromptFromInsight(location.state as string);
      clearState();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCampaignById = async (id: string) => {
    try {
      const res = await dispatch(getCampaignsByIdAction(id));
      if (res?.success === true) {
        // updateUrlWithCampaign(id);
        setSelectedCampaign(res?.data);
        setCampaignSender(
          res?.data?.email_channel === "konvas"
            ? "Konvas AI"
            : !res?.data?.email_channel
            ? "Konvas AI"
            : res?.data?.email_channel
        );
        localStorage.setItem("__created_campaign", JSON.stringify(res?.data));
        localStorage.removeItem("konvas_campaign_id");
        setSelectedTags(
          JSON.parse(res?.data?.ai_tones)?.map((tag: string) => ({ tag }))
        );
        setSelectedWritingApproach(res?.data?.writing_approach);
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
      setSelectedCampaign(null);
      updateUrlWithCampaign(null);
    }
  };

  useEffect(() => {
    const loadInitialCampaign = async () => {
      if (campaignId && typeof campaignId === "string") {
        await getCampaignById(campaignId);
      }
    };

    loadInitialCampaign();
  }, [campaignId]);

  const postAddCampaign = async (data: {
    segment_id: string;
    payload: any;
  }) => {
    try {
      const res = await dispatch(postAddCampaignAction(data));
      if (res?.success === true) {
        await getCampaignById(res?.campaignId);
        setNewCampaignModal(false);
        setIsKonvasCampaignTemplatesModalOpen(false);
        // if (res?.campaign?.status === "draft")
        //   setIsSelectCampaignSenderModalOpen(true);
        await getCampaigns();
        setStatus(res?.campaign?.status);
        dispatch(
          addCampaignForPersonalizationAction({
            id: Number(res.campaignId),
            ...data.payload,
          })
        );

        return res;
      }
    } catch (error) {
      throw error;
    }
  };

  const updateCampaign = async () => {
    setIsRewritingMsg(true);

    const payload = {
      id: selectedCampaign?.id,
      campaign_subject: msgSubject,
      campaign_body: msgContent,
    };

    await dispatch(postUpdateCampaignAction(payload))
      .then((res) => {
        if (res?.success === true) {
          if (saveEditedTemplate) setSaveEditedTemplate(false);
          getCampaignById(selectedCampaign?.id);
        }
      })
      .catch((error) => {
        console.log("error updating campaign:", error);
        if (saveEditedTemplate) setSaveEditedTemplate(false);
      });

    setIsRewritingMsg(false);
  };

  const endCampaign = () => {
    const payload = {
      id: selectedCampaign?.id,
    };

    dispatch(endCampaignAction(payload)).then((res: any) => {
      if (res?.success === true) {
        getCampaigns();
        setStatus(statusOptions[3].value);
        setSelectedCampaign(null);
        getCampaignById(selectedCampaign?.id);
      }
    });
  };

  const pauseCampaign = () => {
    const payload = {
      id: selectedCampaign?.id,
    };

    dispatch(pauseCampaignAction(payload)).then((res: any) => {
      if (res?.success === true) {
        getCampaigns();
        setSelectedCampaign(null);
        getCampaignById(selectedCampaign?.id);
      }
    });
  };

  const continueCampaign = () => {
    const payload = {
      id: selectedCampaign?.id,
    };

    dispatch(continueCampaignAction(payload)).then((res: any) => {
      if (res?.success === true) {
        getCampaigns();
        setSelectedCampaign(null);
        getCampaignById(selectedCampaign?.id);
      }
    });
  };

  const scheduleCampaign = (start_date: string, end_date: string) => {
    const payload = {
      id: selectedCampaign?.id,
      campaign_title: selectedCampaign?.campaign_title,
      start_date,
      end_date,
    };

    if (isCampaignDateChange) {
      dispatch(postUpdateCampaignAction(payload)).then((res: any) => {
        if (res?.success === true) {
          setIsCampaignDateChange(false);
          setIsCampaignScheduleModalOpen(false);
          // getCampaigns();
          getCampaignById(selectedCampaign?.id);
        }
      });
    } else {
      dispatch(scheduleCampaignAction(payload)).then((res: any) => {
        if (res?.success === true) {
          setIsCampaignScheduleModalOpen(false);
          getCampaigns();
          setStatus(statusOptions[1].value);
          setSelectedCampaign(null);
          getCampaignById(selectedCampaign?.id);
        }
      });
    }
  };

  useEffect(() => {
    if (sendTo5000Anyway) {
      startCampaign();
    }
  }, [sendTo5000Anyway]);

  const convertRecipientData = (
    entries: string[]
  ): { email: string; name: string }[] => {
    return entries.map((entry) => {
      const [email, name] = entry.split(";");
      return { email, name };
    });
  };

  const startCampaign = () => {
    let campaign_recipients = JSON.parse(selectedCampaign?.campaign_recipients);
    if (campaign_recipients?.length > 5000 && !sendTo5000Anyway)
      return setIsCampaignRecipientLimitModalOpen(true);
    console.log("msgContent:", typeof msgContent, msgContent);

    if (
      msgContent.includes(
        "https://api.konvas.bid/assets/email-template/images/"
      )
    )
      return setCheckCampaignMessageModal(true);

    campaign_recipients = convertRecipientData(campaign_recipients);
    // campaign_recipients = [
    //   {
    //     email: "okolieuchenna890@gmail.com",
    //     name: "Okolie Uchenna",
    //   },
    //   {
    //     email: "joshuakinwale06@gmail.com",
    //     name: "Joshua Akinwale",
    //   },
    //   {
    //     email: "ayodeji1.adesola@gmail.com",
    //     name: "Ayodeji Adesola",
    //   },
    //   {
    //     email: "dejinownow@gmail.com",
    //     name: "Deji Adesola",
    //   },
    // ];
    const payload = {
      business_slug: selectedCampaign?.slug,
      campaign_id: selectedCampaign?.id,
      campaign_subject: msgSubject,
      campaign_recipients,
    };

    if (campaignSender === "Klaviyo") {
      const klaviyoCampaignId = localStorage.getItem("klaviyo_campaign_id");
      const payload = {
        data: {
          type: "campaign-send-job",
          id: klaviyoCampaignId,
        },
      };
      dispatch(scheduleKlaviyoCampaignSend(payload))
        .then((res: any) => {
          if (res?.success === true) {
            localStorage.removeItem("klaviyo_campaign_id");
            if (isCampaignRecipientLimitModalOpen)
              setIsCampaignRecipientLimitModalOpen(false);
            setSendTo5000Anyway(false);
            getCampaigns();
            setStatus(statusOptions[2].value);
            setSelectedCampaign(null);
            getCampaignById(selectedCampaign?.id);
          }
        })
        .catch((error) => {
          console.log("error staring campaign:", error);
          if (sendTo5000Anyway) setSendTo5000Anyway(false);
        });
    } else {
      dispatch(sendCampaignEmailAction(payload))
        .then((res: any) => {
          if (res?.success === true) {
            if (isCampaignRecipientLimitModalOpen)
              setIsCampaignRecipientLimitModalOpen(false);
            setSendTo5000Anyway(false);
            getCampaigns();
            setStatus(statusOptions[2].value);
            setSelectedCampaign(null);
            getCampaignById(selectedCampaign?.id);
          }
        })
        .catch((error) => {
          console.log("error staring campaign:", error);
          if (sendTo5000Anyway) setSendTo5000Anyway(false);
        });
    }
  };

  const deleteCampaign = () => {
    dispatch(deleteCampaignAction(selectedCampaign.id)).then((res: any) => {
      if (res?.success === true) {
        getCampaigns();
        setIsConfirmationModalOpen(false);
        setSelectedCampaign(null);
      }
    });
  };

  const groupedData = () => {
    // First, map each campaign to include the full timestamp
    const campaignsWithDate = campaignsData?.map((campaign: any) => {
      const date = new Date(
        campaign.status === "draft" ? campaign.created_at : campaign.start_date
      );
      return {
        ...campaign,
        timestamp: date.getTime(),
        // Format date without time for grouping
        dateString: date.toLocaleDateString(),
      };
    });

    // First sort by timestamp in descending order (most recent first)
    const sortedCampaigns = campaignsWithDate.sort(
      (a: any, b: any) => b.timestamp - a.timestamp
    );

    // Group by date
    const grouped = sortedCampaigns.reduce((acc: any, campaign: any) => {
      acc[campaign.dateString] = acc[campaign.dateString] || [];
      acc[campaign.dateString].push(campaign);
      return acc;
    }, Object.create(null));

    // Convert to array and ensure each group is properly sorted by time
    return (
      Object.entries(grouped)
        .map(([dateString, campaigns]: any) => ({
          createdAt: dateString,
          // Sort campaigns within each date group by timestamp in descending order
          data: campaigns.sort((a: any, b: any) => b.timestamp - a.timestamp),
        }))
        // Finally, sort the date groups themselves
        .sort((a, b) => {
          const dateA = new Date(a.createdAt).getTime();
          const dateB = new Date(b.createdAt).getTime();
          return dateB - dateA;
        })
    );
  };

  const campaignsDataToShow =
    listOrder?.value === listOrderOptions[0].value
      ? groupedData()
      : groupedData().reverse();

  const handleSelectRow = (rowId: any) => {
    if (selectedRows?.some((x) => rowId?.includes(x))) {
      setSelectedRows(selectedRows?.filter((x: string) => x !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const isRowSelected = (rowId: any) => {
    return selectedRows?.some((x) => rowId?.includes(x));
  };

  const contentOptions = [
    { label: "Edit", action: () => setIsEditingMsgContent(true) },
    { label: "Rewrite", action: () => rewriteMsg("") },
  ];

  const updateMsgContentAnchorElements = (string: string) => {
    if (string) {
      // Define the regex pattern to match an anchor element and its content
      const anchorRegex =
        /<a\s+([^>]*\s+)?href=['"]([^'"]+)['"]([^>]*)>([^<]+)<\/a>/g;

      // Use the replace method to modify each match
      const updatedString = string?.replace(
        anchorRegex,
        (match, p1, href, p3, text) => {
          const newHref = `${href}/k-{$customerEmail}`;
          const targetAttribute = /target=['"]_blank['"]/.test(match)
            ? ""
            : ' target="_blank"';
          return `<a ${
            p1 ? p1.trim() + " " : ""
          }href="${newHref}"${targetAttribute}${
            p3 ? " " + p3.trim() : ""
          }>${text}</a>`;
        }
      );

      return updatedString || "";
    }
  };

  // const msgContentRevamp = (msg: any) => {
  //   var value = msg
  //     ?.replace(/\\n/g, "\n\n")
  //     ?.replace(/\\n \\n/g, "\n\n\n")
  //     ?.replace("```html\n", "")
  //     ?.replace("\n```", "")
  //     ?.replace("[Customer Name]", "{$customerName}");
  //   value = updateMsgContentAnchorElements(value);

  //   return value || "";
  // };

  const rewriteMsg = async (additionalContext: string) => {
    console.log("additionalContext:", additionalContext);
    try {
      if (!isRewritingMsg) {
        setIsRewritingMsg(true);

        const currentBrandTones = selectedTags?.map(
          (data: { tag: string }) => data.tag
        );

        console.log("Selected tones being processed:", currentBrandTones);

        console.log(
          "Initial AI tones from campaign:",
          selectedCampaign.ai_tones
        );

        const parsedInitialTones = selectedCampaign.ai_tones
          ? JSON.parse(selectedCampaign.ai_tones)
          : [];
        console.log("Parsed initial tones:", parsedInitialTones);

        const brandTones = JSON.stringify(
          currentBrandTones.length === 0
            ? parsedInitialTones
            : currentBrandTones
        );
        console.log("Final tones being sent to API:", brandTones);

        const writingApproach = selectedWritingApproach;
        console.log("Writing approach:", writingApproach);

        const res = await dispatch(
          rewriteCampaignMsgAction(
            selectedCampaign?.id,
            brandTones,
            additionalContext,
            writingApproach
          )
        );
        if (res?.success === true) {
          if (res?.data) {
            console.log("initialRewrittenMessageString", res?.data);
            const emailMessage = convertEscapedStringToHTML(res?.data);
            console.log("finalFormattedRewrittenMessage", emailMessage);
            setMsgContent(emailMessage);
            const updateCampaignPayload = {
              id: selectedCampaign?.id,
              campaign_title: selectedCampaign?.campaign_title,
              ai_tones: brandTones,
              writing_approach: writingApproach,
              campaign_body: res?.data,
            };
            await dispatch(postUpdateCampaignAction(updateCampaignPayload));
          }
        }

        setIsRewritingMsg(false);
      }
    } catch (error) {
      setIsRewritingMsg(false);
      console.log("error", error);
      dispatch(setAlert(true, "error", "Something went wrong, try again"));
    }
  };

  const dateDisplay = (startDate: Date, endDate: Date) => {
    return `${
      moment(startDate).format("MMM DD, YYYY") +
      " - " +
      moment(endDate).format("MMM DD, YYYY")
    }`;
  };

  const sunEditorOptions = {
    fontSize: [14, 16, 20, 24, 26],
    buttonList: [
      [
        "undo",
        "redo",
        "fontSize",
        "fontColor",
        "hiliteColor",
        "bold",
        "italic",
        "underline",
      ],
      ["align", "list", "image", "link", "codeView"],
    ],
    // fullPage: true,
    // mode: "classic",
    doctype: "<!DOCTYPE html>",
    defaultStyle: "body{margin:0}",
    disableHtmlSanitizer: true,
    attributesWhitelist: {
      all: "class style id",
      // div: "class style",
      // p: "class style",
      // span: "class style",
      // img: "src alt class",
    },
    allowClassName: true,
    removeFormatAttr: null,
    removeFormatTags: null,
    // templates: [
    //   {
    //     name: "Email Template",
    //     html: `<!DOCTYPE html>
    //            <html>
    //            <head>
    //              <link rel="stylesheet" href="your-styles.css">
    //            </head>
    //            <body>
    //              <div>Your template content</div>
    //            </body>
    //            </html>`,
    //   },
    // ],
  };

  // const campaignsDataToShow =
  //   listOrder?.value === listOrderOptions[0].value
  //     ? groupedData() || []
  //     : groupedData().reverse() || [];

  return (
    <React.Fragment>
      <PageTitle title="Campaigns" />
      <Topbar
        title="Campaigns"
        onSearch={() => {}}
        filters={topBarFilters}
        actionBtn={
          <Button
            text="New Campaign"
            leftIcon="plus"
            onClick={() => setNewCampaignModal(true)}
          />
        }
      />

      <div className="page_container campaign_page_container">
        {(isFetchingCampaigns || isFetchingBrandData) && <Loader />}

        {!isFetchingCampaigns && !isFetchingBrandData && (
          <div
            className={classNames("campaign_page_container_inner", {
              block_display: isCampaignReportExpanded,
              is_fetching_campaign_data: isFetchingCampaignData,
            })}
          >
            <div
              className={classNames("campaigns_list", {
                is_visible: !selectedCampaign,
                not_visible: isCampaignReportExpanded,
              })}
            >
              <div className="status_sections">
                <div className="side">
                  {statusOptions?.map((item: any, i: any) => (
                    <p
                      key={i}
                      className={classNames("status", {
                        active: item.value === status,
                      })}
                      onClick={() => {
                        setStatus(item.value);
                        setSelectedCampaign(null);
                        updateUrlWithCampaign(null);
                      }}
                    >
                      {item.label}
                    </p>
                  ))}
                </div>

                <div className="side">
                  <div className="icon" onClick={() => getCampaigns()}>
                    <Icon name="refresh" />
                  </div>
                </div>
              </div>

              {listLayout === APP_LIST_LAYOUT_LIST ? (
                <div className="layout list_layout scroll-height">
                  {campaignsDataToShow?.length === 0 ? (
                    <NoData
                      text={`No  ${
                        status === "draft" ? "drafted" : status
                      } campaigns at the moment`}
                    />
                  ) : (
                    campaignsDataToShow?.map((campaign: any, index: any) => (
                      <React.Fragment key={index}>
                        <p className="date_title">
                          {/* {dateToFromNow(campaign.createdAt)} */}
                          {/* {moment(campaign?.createdAt).fromNow()} */}
                          {campaign?.createdAt}
                        </p>

                        <div className="list">
                          {campaign?.data?.map((row: any, i: any) => (
                            <div
                              key={i}
                              className={classNames("row", {
                                active: selectedCampaign?.id === row.id,
                              })}
                            >
                              <div className="left">
                                <FormInput
                                  id="selectRow"
                                  type="checkbox"
                                  value={isRowSelected(row?.campaign_id)}
                                  onChange={() =>
                                    handleSelectRow(row?.campaign_id)
                                  }
                                  readOnly={isFetchingCampaigns}
                                />

                                <p
                                  className="title"
                                  onClick={() => updateUrlWithCampaign(row?.id)}
                                >
                                  {row.campaign_title}
                                </p>
                              </div>

                              <p className="time">
                                {moment(row.created_at).format("hh: mm A")}
                              </p>
                            </div>
                          ))}
                        </div>
                      </React.Fragment>
                    ))
                  )}
                </div>
              ) : (
                // <div className="layout grid_layout">
                //   {campaignsData?.map((row: any, i: any) => (
                //     <div
                //       key={i}
                //       className={classNames("row", {
                //         active: selectedCampaign?.id === row.id,
                //       })}
                //     >
                //       <div className="row_inner">
                //         <div className="top">
                //           <p
                //             className="title"
                //             onClick={() => updateUrlWithCampaign(row?.id)}
                //           >
                //             {row.campaign_title}
                //           </p>

                //           <p className="time">
                //             {moment(row.created_at).format("MMM DD")}
                //           </p>
                //         </div>

                //         <p className="subject">{row.campaign_subject}</p>
                //         <p className="content">{row.campaign_body}</p>
                //       </div>
                //     </div>
                //   ))}
                // </div>
                <></>
              )}
            </div>

            {selectedCampaign ? (
              selectedCampaign.status === "scheduled" ? (
                <CampaignSchedule
                  campaignData={selectedCampaign}
                  isStartingCampaign={isStartingCampaign}
                  startCampaign={startCampaign}
                >
                  <CampaignDetails
                    isFetchingCampaignData={isFetchingCampaignData}
                    selectedCampaign={selectedCampaign}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    campaignSender={campaignSender}
                    setCampaignSender={setCampaignSender}
                    selectedWritingApproach={selectedWritingApproach}
                    setSelectedWritingApproach={setSelectedWritingApproach}
                    analytics={analytics}
                    setSelectedCampaign={setSelectedCampaign}
                    setIsCampaignAiAgentModalOpen={
                      setIsCampaignAiAgentModalOpen
                    }
                    setIsCampaignRecepientModalOpen={
                      setIsCampaignRecepientModalOpen
                    }
                    setIsKlaviyoAuthModalOpen={setIsKlaviyoAuthModalOpen}
                    rewriteMsg={rewriteMsg}
                    setIsCampaignScheduleModalOpen={
                      setIsCampaignScheduleModalOpen
                    }
                    setIsCampaignDateChange={setIsCampaignDateChange}
                    dateDisplay={dateDisplay}
                    contentOptions={contentOptions}
                    isRewritingMsg={isRewritingMsg}
                    isEditingMsgContent={isEditingMsgContent}
                    msgSubject={msgSubject}
                    setMsgSubject={setMsgSubject}
                    msgContent={msgContent}
                    setMsgContent={setMsgContent}
                    getSunEditorInstance={getSunEditorInstance}
                    sunEditorOptions={sunEditorOptions}
                    setIsEditingMsgContent={setIsEditingMsgContent}
                    setCampaignInitialSubjectAndContent={
                      setCampaignInitialSubjectAndContent
                    }
                    updateCampaign={updateCampaign}
                    setSaveEditedTemplate={setSaveEditedTemplate}
                    isStartingCampaign={isStartingCampaign}
                    startCampaign={startCampaign}
                    setIsConfirmationModalOpen={setIsConfirmationModalOpen}
                    isCampaignReportExpanded={isCampaignReportExpanded}
                    setIsCampaignReportExpanded={setIsCampaignReportExpanded}
                    pauseCampaign={pauseCampaign}
                    continueCampaign={continueCampaign}
                    isPausingCampaign={isPausingCampaign}
                    isContinuingCampaign={isContinuingCampaign}
                    isEndingCampaign={isEndingCampaign}
                    endCampaign={endCampaign}
                  />
                </CampaignSchedule>
              ) : (
                <CampaignDetails
                  // Same props as above
                  isFetchingCampaignData={isFetchingCampaignData}
                  selectedCampaign={selectedCampaign}
                  selectedTags={selectedTags}
                  setSelectedTags={setSelectedTags}
                  campaignSender={campaignSender}
                  setCampaignSender={setCampaignSender}
                  selectedWritingApproach={selectedWritingApproach}
                  setSelectedWritingApproach={setSelectedWritingApproach}
                  analytics={analytics}
                  setSelectedCampaign={setSelectedCampaign}
                  setIsCampaignAiAgentModalOpen={setIsCampaignAiAgentModalOpen}
                  setIsCampaignRecepientModalOpen={
                    setIsCampaignRecepientModalOpen
                  }
                  setIsCampaignDateChange={setIsCampaignDateChange}
                  setIsKlaviyoAuthModalOpen={setIsKlaviyoAuthModalOpen}
                  rewriteMsg={rewriteMsg}
                  setIsCampaignScheduleModalOpen={
                    setIsCampaignScheduleModalOpen
                  }
                  dateDisplay={dateDisplay}
                  contentOptions={contentOptions}
                  isRewritingMsg={isRewritingMsg}
                  isEditingMsgContent={isEditingMsgContent}
                  msgSubject={msgSubject}
                  setMsgSubject={setMsgSubject}
                  msgContent={msgContent}
                  setMsgContent={setMsgContent}
                  getSunEditorInstance={getSunEditorInstance}
                  sunEditorOptions={sunEditorOptions}
                  setIsEditingMsgContent={setIsEditingMsgContent}
                  setCampaignInitialSubjectAndContent={
                    setCampaignInitialSubjectAndContent
                  }
                  updateCampaign={updateCampaign}
                  setSaveEditedTemplate={setSaveEditedTemplate}
                  isStartingCampaign={isStartingCampaign}
                  startCampaign={startCampaign}
                  setIsConfirmationModalOpen={setIsConfirmationModalOpen}
                  isCampaignReportExpanded={isCampaignReportExpanded}
                  setIsCampaignReportExpanded={setIsCampaignReportExpanded}
                  pauseCampaign={pauseCampaign}
                  continueCampaign={continueCampaign}
                  isPausingCampaign={isPausingCampaign}
                  isContinuingCampaign={isContinuingCampaign}
                  isEndingCampaign={isEndingCampaign}
                  endCampaign={endCampaign}
                />
              )
            ) : isExplicitlySearching && !isFetchingCampaignData ? (
              <div className="campaign_details is_visible">
                <div className="flex items-center justify-center h-full">
                  <div className="text-center p-8">
                    <h2 className="text-xl font-semibold text-gray-700 mb-2">
                      Campaign Not Found
                    </h2>
                    <p className="text-gray-500 mb-4">
                      The campaign you're looking for doesn't exist or has been
                      deleted.
                    </p>
                  </div>
                </div>
              </div>
            ) : null}

            {isCampaignReportExpanded && (
              <div className="campaign_details">
                <CampaignReport
                  reportData={{
                    ...analytics,
                    ...selectedCampaign,
                  }}
                  isCampaignReportExpanded={isCampaignReportExpanded}
                  setIsCampaignReportExpanded={setIsCampaignReportExpanded}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <NewCampaignModal
        isOpen={newCampaignModal}
        close={() => setNewCampaignModal(false)}
        onSubmit={(payload) => postAddCampaign(payload)}
        brandData={brandData}
        initialCampaignPromptFromInsight={initialCampaignPromptFromInsight}
        setIsSelectCampaignSenderModalOpen={setIsSelectCampaignSenderModalOpen}
        setUpdateBrandAssetsModal={setUpdateBrandAssetsModal}
      />

      <UpdateBrandAssetsModal
        isOpen={updateBrandAssetsModal}
        close={() => setUpdateBrandAssetsModal(false)}
      />
      <CampaignAIAgentModal
        isOpen={isCampaignAiAgentModalOpen}
        close={() => setIsCampaignAiAgentModalOpen(false)}
        aiDetails={selectedCampaign?.ai_details}
      />

      {selectedCampaign ? (
        <CampaignRecepientModal
          isOpen={isCampaignRecepientModalOpen}
          close={() => setIsCampaignRecepientModalOpen(false)}
          campaignRecepients={JSON.parse(selectedCampaign?.campaign_recipients)}
        />
      ) : (
        <></>
      )}

      <CampaignScheduleModal
        isOpen={isCampaignScheduleModalOpen}
        close={() => {
          setIsCampaignScheduleModalOpen(false);
          setIsCampaignDateChange(false);
        }}
        scheduleCampaign={scheduleCampaign}
        isSchedulingCampaign={isSchedulingCampaign}
        isUpdatingCampaign={isUpdatingCampaign}
        isCampaignDateChange={isCampaignDateChange}
      />

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        close={() => setIsConfirmationModalOpen(false)}
        confirmationText={`Are you sure you want to delete ${selectedCampaign?.campaign_title}?`}
        onSubmit={deleteCampaign}
        loading={isDeletingCampaign}
      />

      {isSelectCampaignSenderModalOpen && (
        <SelectCampaignSenderModal
          isOpen={isSelectCampaignSenderModalOpen}
          close={() => setIsSelectCampaignSenderModalOpen(false)}
          selectedCampaign={selectedCampaign}
          setIsKlaviyoAuthModalOpen={setIsKlaviyoAuthModalOpen}
          setIsKonvasCampaignTemplatesModalOpen={
            setIsKonvasCampaignTemplatesModalOpen
          }
        />
      )}

      <KlaviyoAuthModal
        isOpen={isKlaviyoAuthModalOpen}
        open={(isOpen: boolean) => setIsKlaviyoAuthModalOpen(isOpen)}
        getCampaignById={getCampaignById}
        setIsKlaviyoTemplateModalOpen={setIsKlaviyoTemplateModalOpen}
      />

      {isCampaignRecipientLimitModalOpen && (
        <CampaignRecipientLimitModal
          isOpen={isCampaignRecipientLimitModalOpen}
          close={() => setIsCampaignRecipientLimitModalOpen(false)}
          isStartingCampaign={isStartingCampaign}
          setSendTo5000Anyway={setSendTo5000Anyway}
          startCampaign={startCampaign}
        />
      )}

      {isKlaviyoTemplateModalOpen && (
        <KlaviyoTemplateModal
          isOpen={isKlaviyoTemplateModalOpen}
          close={() => setIsKlaviyoTemplateModalOpen(false)}
        />
      )}

      {checkCampaignMessageModal && (
        <CheckCampaignMessageModal
          isOpen={checkCampaignMessageModal}
          close={() => setCheckCampaignMessageModal(false)}
        />
      )}

      {isKonvasCampaignTemplatesModalOpen && (
        <KonvasCampaignTemplatesModal
          isOpen={isKonvasCampaignTemplatesModalOpen}
          open={(isOpen: boolean) =>
            setIsKonvasCampaignTemplatesModalOpen(isOpen)
          }
          onSubmit={(payload) => postAddCampaign(payload)}
          brandData={brandData}
        />
      )}
    </React.Fragment>
  );
};

export default CampaignsPage;
