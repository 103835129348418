import React, { useState } from "react";
import { Modal } from "reactstrap";

import SectionHeader from "../section-header";
import Button from "../button";

import Icon from "../../assets/svg";

type Props = {
  isOpen: boolean;
  close: any;
  isStartingCampaign: boolean;
  startCampaign?: () => void;
  setSendTo5000Anyway: React.Dispatch<React.SetStateAction<boolean>>;
};

const CampaignRecipientModal = ({
  isOpen,
  close,
  isStartingCampaign,
  setSendTo5000Anyway,
}: Props) => {
  const [isUpgrade, setIsUpgrade] = useState(false);
  return (
    <Modal className="campaign_recipient_limit_modal" isOpen={isOpen}>
      <div className="modal_header">
        <SectionHeader
          leftSide={<div className="title_box">UPGRADE</div>}
          rightSide={
            <>
              <div
                className="icon"
                onClick={() => {
                  setSendTo5000Anyway(false);
                  close();
                }}
              >
                <Icon name="close" />
              </div>
            </>
          }
        />
      </div>

      <div className="modal_body">
        <div className="content_box">
          <p>
            {isUpgrade
              ? "Thank you for picking an upgrade, we will get back to you."
              : "You are about to send this campaign to only 5000 customers as part of your free tier. To upgrade, click the button below"}
          </p>
          <div className="btn_box">
            {!isUpgrade && (
              <Button
                text="Upgrade"
                onClick={() => {
                  setIsUpgrade(true);
                }}
                className="btn_secondary"
              />
            )}
            {isUpgrade ? (
              <Button
                text="Continue"
                onClick={() => {
                  setSendTo5000Anyway(false);
                  close();
                }}
              />
            ) : (
              <Button
                text="Send 5000 Anyway"
                onClick={() => {
                  console.log("starting campaign");
                  setSendTo5000Anyway(true);
                  // startCampaign();
                }}
                loading={isStartingCampaign}
                disabled={isStartingCampaign}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CampaignRecipientModal;
