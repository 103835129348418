import { createSlice } from "@reduxjs/toolkit";
import { API_URLS, getRequest, postRequest } from "../../api";

import { setAlert } from "../components/components-slice";

import { getRequestError, handleFetchAPIError } from "../../utils/functions";
import axios from "axios";

interface OpenRateAnalytics {
  businessSlug: string;
  totalCampaigns: number;
  successfullyFetchedCampaigns: number;
  periodStart: string;
  periodEnd: string;
  groupBy: string;
  totals: {
    queued: number;
    sent: number;
    delivered: number;
    opened: number;
    opened_unique: number;
  };
  rates: {
    deliveryRate: number;
    openRate: number;
    bounceRate: number;
  };
}


type Props = {
  isFetchingCampaigns: boolean;
  campaigns: [];
  isFetchingCampaignData: boolean;
  isGettingPersonalizedCampaigns: boolean;
  isCreatingCampaign: boolean;
  isSavingCampaign: boolean;
  isSavingCampaignSuccessful: boolean;
  isSavingCampaignEmailChannel: boolean;
  isDeletingCampaign: boolean;
  isEndingCampaign: boolean;
  isContinuingCampaign: boolean;
  isPausingCampaign: boolean;
  isSchedulingCampaign: boolean;
  isUpdatingCampaign: boolean;
  isStartingCampaign: boolean;
  isFetchingCampaignAnalytics: boolean;
  isFetchingKonvasCampaignTemplates: boolean;
  konvasCampaignTemplates: any;
  isFetchingKonvasCampaignTemplateById: boolean;
  konvasTemplate: any;
  isFetchingUniqueOpenRates: boolean;
  uniqueOpenRates: any;
  openRateAnalytics: OpenRateAnalytics | null;
  error: string | null;
};
export const initialState: Props = {
  isFetchingCampaigns: false,
  campaigns: [],
  isFetchingCampaignData: false,
  isGettingPersonalizedCampaigns: true,
  isCreatingCampaign: false,
  isSavingCampaign: false,
  isSavingCampaignSuccessful: true,
  isSavingCampaignEmailChannel: false,
  isDeletingCampaign: false,
  isEndingCampaign: false,
  isContinuingCampaign: false,
  isPausingCampaign: false,
  isSchedulingCampaign: false,
  isUpdatingCampaign: false,
  isStartingCampaign: false,
  isFetchingCampaignAnalytics: false,
  isFetchingKonvasCampaignTemplates: false,
  konvasCampaignTemplates: null,
  isFetchingKonvasCampaignTemplateById: false,
  konvasTemplate: null,
  isFetchingUniqueOpenRates: false,
  uniqueOpenRates: null,
  openRateAnalytics: null,
  error: null,
};

// Slice
const slice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setIsFetchingCampaigns: (state, { payload }) => {
      state.isFetchingCampaigns = payload;
    },
    getCampaignsSuccess: (state, { payload }) => {
      state.campaigns = payload;
    },
    setIsFetchingCampaignData: (state, { payload }) => {
      state.isFetchingCampaignData = payload;
    },
    setIsGettingPersonalizedCampaigns: (state, { payload }) => {
      state.isGettingPersonalizedCampaigns = payload;
    },
    setIsCreatingCampaign: (state, { payload }) => {
      state.isCreatingCampaign = payload;
    },
    setIsSavingCampaign: (state, { payload }) => {
      state.isSavingCampaign = payload;
    },
    setIsSavingCampaignSuccessful: (state, { payload }) => {
      state.isSavingCampaignSuccessful = payload;
    },

    setIsSavingCampaignEmailChannel: (state, { payload }) => {
      state.isSavingCampaignEmailChannel = payload;
    },

    setIsDeletingCampaign: (state, { payload }) => {
      state.isDeletingCampaign = payload;
    },
    setIsEndingCampaign: (state, { payload }) => {
      state.isEndingCampaign = payload;
    },
    setIsContinuingCampaign: (state, { payload }) => {
      state.isContinuingCampaign = payload;
    },
    setIsPausingCampaign: (state, { payload }) => {
      state.isPausingCampaign = payload;
    },
    setIsSchedulingCampaign: (state, { payload }) => {
      state.isSchedulingCampaign = payload;
    },
    setIsUpdatingCampaign: (state, { payload }) => {
      state.isUpdatingCampaign = payload;
    },
    setIsStartingCampaign: (state, { payload }) => {
      state.isStartingCampaign = payload;
    },
    setIsFetchingCampaignAnalytics: (state, { payload }) => {
      state.isFetchingCampaignAnalytics = payload;
    },
    setIsFetchingKonvasCampaignTemplates: (state, { payload }) => {
      state.isFetchingKonvasCampaignTemplates = payload;
    },
    getKonvasCampaignTemplatesSuccess: (state, { payload }) => {
      state.konvasCampaignTemplates = payload;
    },
    setIsFetchingKonvasCampaignTemplateById: (state, { payload }) => {
      state.isFetchingKonvasCampaignTemplateById = payload;
    },
    getKonvasCampaignTemplateByIdSuccess: (state, { payload }) => {
      state.konvasTemplate = payload;
    },
    setIsFetchingUniqueOpenRates: (state, { payload }) => {
      state.isFetchingUniqueOpenRates = payload;
    },
    getUniqueOpenRatesSuccess: (state, { payload }) => {
      state.uniqueOpenRates = payload;
      state.openRateAnalytics = payload.data;
      state.error = null;
    },
  },
});
export default slice.reducer;

// Actions
const {
  setIsFetchingCampaigns,
  getCampaignsSuccess,
  setIsFetchingCampaignData,
  setIsGettingPersonalizedCampaigns,
  setIsCreatingCampaign,
  setIsSavingCampaign,
  setIsSavingCampaignSuccessful,
  setIsSavingCampaignEmailChannel,
  setIsDeletingCampaign,
  setIsEndingCampaign,
  setIsContinuingCampaign,
  setIsPausingCampaign,
  setIsSchedulingCampaign,
  setIsUpdatingCampaign,
  setIsStartingCampaign,
  setIsFetchingCampaignAnalytics,
  setIsFetchingKonvasCampaignTemplates,
  getKonvasCampaignTemplatesSuccess,
  setIsFetchingKonvasCampaignTemplateById,
  getKonvasCampaignTemplateByIdSuccess,
  setIsFetchingUniqueOpenRates,
  getUniqueOpenRatesSuccess,
} = slice.actions;

export const getCampaignsAction = () => async (dispatch: any) => {
  try {
    dispatch(setIsFetchingCampaigns(true));

    const res = await getRequest({
      url: API_URLS?.getCampaigns,
      params: null,
    });

    dispatch(getCampaignsSuccess(res?.data?.data));
    dispatch(setIsFetchingCampaigns(false));

    return { success: true };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsFetchingCampaigns(false));
  }
};

interface RequestResponse {
  success: boolean;
  data?: any;
  error?: string;
  isTimeout?: boolean;
}

interface RequestResponse {
  success: boolean;
  data?: any;
  error?: string;
  isTimeout?: boolean;
}

const TIMEOUT = 60000;

export const getUniqueOpenRatesAction =
  (startDate: string, endDate: string) =>
    async (dispatch: any): Promise<RequestResponse> => {
      console.log('Starting getUniqueOpenRatesAction with dates:', { startDate, endDate });

      try {
        dispatch(setIsFetchingUniqueOpenRates(true));

        const requestConfig = {
          url: `${API_URLS?.getCampaignOpenRateAnalytics}`,
          params: {
            startDate,
            endDate,
            groupBy: 'days'
          },
          timeout: TIMEOUT
        };

        const res = await getRequest(requestConfig);
        console.log('Received API response:', res);

        dispatch(setIsFetchingUniqueOpenRates(false));

        if (res?.data?.status === true) {
          dispatch(getUniqueOpenRatesSuccess({
            status: true,
            data: res.data.data
          }));
          return { success: true, data: res.data };
        } else {
          dispatch(getUniqueOpenRatesSuccess({
            status: true,
            data: {
              businessSlug: '',
              totalCampaigns: 0,
              successfullyFetchedCampaigns: 0,
              periodStart: startDate,
              periodEnd: endDate,
              groupBy: 'days',
              totals: {
                queued: 0,
                sent: 0,
                delivered: 0,
                opened: 0,
                opened_unique: 0
              },
              rates: {
                deliveryRate: 0,
                openRate: 0,
                bounceRate: 0
              }
            }
          }));
          return { success: true, data: null };
        }

      } catch (err: any) {
        console.error('Request failed:', err);
        dispatch(setIsFetchingUniqueOpenRates(false));

        if (err.response?.status === 404 || err.response?.data?.message?.includes('No campaigns found')) {
          dispatch(getUniqueOpenRatesSuccess({
            status: true,
            data: {
              businessSlug: '',
              totalCampaigns: 0,
              successfullyFetchedCampaigns: 0,
              periodStart: startDate,
              periodEnd: endDate,
              groupBy: 'days',
              totals: {
                queued: 0,
                sent: 0,
                delivered: 0,
                opened: 0,
                opened_unique: 0
              },
              rates: {
                deliveryRate: 0,
                openRate: 0,
                bounceRate: 0
              }
            }
          }));
          return { success: true, data: null };
        }

        return {
          success: false,
          error: getRequestError(err),
          isTimeout: err.code === 'ECONNABORTED' || err.response?.status === 504
        };
      }
    };

export const getCampaignsByIdAction = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setIsFetchingCampaignData(true));

    const res = await getRequest({
      url: `${API_URLS?.getCampaignById}/${id}`,
      params: null,
    });

    dispatch(setIsFetchingCampaignData(false));

    return { success: true, data: res?.data };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsFetchingCampaignData(false));
  }
};
export const getPersonalizedCampaignsByIdAction =
  (id: string) => async (dispatch: any) => {
    try {
      dispatch(setIsGettingPersonalizedCampaigns(true));
      const res = await getRequest({
        url: `${API_URLS?.getPersonalizedCampaignById}/${id}`,
        params: null,
      });

      dispatch(setIsGettingPersonalizedCampaigns(false));

      return { success: true, data: res?.data };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
      dispatch(setIsGettingPersonalizedCampaigns(false));
    }
  };

export const generateAICampaignAction = () => async (dispatch: any) => {
  try {
    dispatch(setIsCreatingCampaign(true));
    const response = await fetch(API_URLS?.generateAICampaign);
    await handleFetchAPIError(response);
    const resp = await response.json();

    dispatch(setIsCreatingCampaign(false));
    return { success: true, data: resp?.data };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsCreatingCampaign(false));
  }
};

export const generateAICustomCampaignAction =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(setIsCreatingCampaign(true));
      const response = await fetch(
        data?.segment_name
          ? API_URLS?.generateAISegmentCampaign
          : API_URLS?.generateAICustomCampaign,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      await handleFetchAPIError(response);

      const resp = await response.json();

      dispatch(setIsCreatingCampaign(false));
      return { success: true, data: resp?.data };
    } catch (err) {
      console.log("error__:", err);
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsCreatingCampaign(false));
    }
  };

export const stopGeneratingCampaignAction = () => async (dispatch: any) => {
  dispatch(setIsCreatingCampaign(false));
};

export const rewriteUnsavedCampaignBodyAction =
  (data: { campaign_body: string }) => async (dispatch: any) => {
    try {
      const response = await fetch(API_URLS?.rewriteUnsavedCampaignBody, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      await handleFetchAPIError(response);

      const resp = await response.json();

      return { success: true, data: resp?.data };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
    }
  };

export const rewriteCampaignMsgAction =
  (
    id: string,
    brandTones: string,
    additionalContext: string,
    writingApproach: string
  ) =>
    async (dispatch: any) => {
      try {
        const response = await fetch(`${API_URLS?.rewriteCampaignMsg}/${id}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            additionalContext,
            writingApproach,
            brandTones,
          }),
        });

        await handleFetchAPIError(response);

        const resp = await response.json();

        return { success: true, data: resp?.data };
      } catch (err) {
        const errorMessage = getRequestError(err);
        dispatch(setAlert(true, "error", errorMessage));
      }
    };

export const saveCampaignEmailChannelAction =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(setIsSavingCampaignEmailChannel(true));

      await postRequest({
        url: API_URLS?.saveCampaignEmailChannel,
        params: null,
        data,
        formData: false,
      });

      // dispatch(setAlert(true, "success", res?.data?.message));
      dispatch(setIsSavingCampaignEmailChannel(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
      dispatch(setIsSavingCampaignEmailChannel(false));
    }
  };

export const postUpdateCampaignAction =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch(setIsUpdatingCampaign(true));
      const res = await postRequest({
        url: API_URLS?.updateCampaign,
        params: null,
        data,
        formData: false,
      });

      dispatch(setAlert(true, "success", res?.data?.message));
      dispatch(setIsUpdatingCampaign(false));

      return { success: true };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));
      dispatch(setIsUpdatingCampaign(false));
      throw new Error(errorMessage);
    }
  };

export const addCampaignForPersonalizationAction =
  (data: any) => async (dispatch: any) => {
    try {
      // dispatch(setIsSavingCampaign(true));

      const res = await postRequest({
        url: API_URLS?.addCampaignForPersonalization,
        params: null,
        data,
        formData: false,
      });

      // dispatch(setAlert(true, "success", res?.data?.message));
      // dispatch(setIsSavingCampaign(false));

      return {
        success: true,
        campaign: res?.data?.data,
        campaignId: res?.data?.campaign_id,
      };
    } catch (err) {
      const errorMessage = getRequestError(err);
      // dispatch(setAlert(true, "error", errorMessage));

      // dispatch(setIsSavingCampaignSuccessful(false));
      // dispatch(setIsSavingCampaign(false));
      throw new Error(errorMessage);
    }
  };

export const postAddCampaignAction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setIsSavingCampaign(true));

    const res = await postRequest({
      url: data?.segment_id
        ? API_URLS?.addSegmentCampaign
        : API_URLS?.addCustomCampaign,
      params: null,
      data: data.payload,
      formData: false,
    });

    dispatch(setAlert(true, "success", res?.data?.message));
    dispatch(setIsSavingCampaign(false));

    return {
      success: true,
      campaign: res?.data?.data,
      campaignId: res?.data?.campaign_id,
    };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsSavingCampaignSuccessful(false));
    dispatch(setIsSavingCampaign(false));
    throw new Error(errorMessage);
  }
};

export const deleteCampaignAction = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setIsDeletingCampaign(true));

    const res = await getRequest({
      url: `${API_URLS?.deleteCampaign}/${id}`,
      params: null,
    });

    dispatch(setIsDeletingCampaign(false));
    return { success: res?.data?.status };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsDeletingCampaign(false));
  }
};

export const endCampaignAction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setIsEndingCampaign(true));

    const res = await postRequest({
      url: API_URLS?.endCampaign,
      params: null,
      data,
      formData: false,
    });

    if (res?.data?.status === true) {
      dispatch(setIsEndingCampaign(false));
      dispatch(setAlert(true, "success", "Campaign ended successfully!"));
      return { success: true };
    } else {
      dispatch(setIsEndingCampaign(false));
    }
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsEndingCampaign(false));
  }
};

export const continueCampaignAction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setIsContinuingCampaign(true));

    const res = await postRequest({
      url: API_URLS?.continueCampaign,
      params: null,
      data,
      formData: false,
    });

    if (res?.data?.status === true) {
      dispatch(setIsContinuingCampaign(false));
      dispatch(setAlert(true, "success", "Campaign continued successfully!"));
      return { success: true };
    } else {
      dispatch(setIsContinuingCampaign(false));
    }
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsContinuingCampaign(false));
  }
};

export const pauseCampaignAction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setIsPausingCampaign(true));

    const res = await postRequest({
      url: API_URLS?.pauseCampaign,
      params: null,
      data,
      formData: false,
    });

    if (res?.data?.status === true) {
      dispatch(setIsPausingCampaign(false));
      dispatch(setAlert(true, "success", "Campaign paused successfully!"));
      return { success: true };
    } else {
      dispatch(setIsPausingCampaign(false));
    }
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsPausingCampaign(false));
  }
};

export const scheduleCampaignAction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setIsSchedulingCampaign(true));

    const res = await postRequest({
      url: API_URLS?.scheduleCampaign,
      params: null,
      data,
      formData: false,
    });

    if (res?.data?.status === true) {
      dispatch(setIsSchedulingCampaign(false));
      dispatch(setAlert(true, "success", "Campaign scheduled successfully!"));
      return { success: true };
    } else {
      dispatch(setIsSchedulingCampaign(false));
    }
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsSchedulingCampaign(false));
  }
};

export const sendCampaignEmailAction = (data: any) => async (dispatch: any) => {
  try {
    dispatch(setIsStartingCampaign(true));

    const res = await postRequest({
      url: API_URLS?.sendCampaignEmail,
      params: null,
      data,
      formData: false,
    });

    if (res?.data?.status === true) {
      dispatch(setIsStartingCampaign(false));
      dispatch(setAlert(true, "success", res?.data?.message));
      return { success: true };
    } else {
      dispatch(setIsStartingCampaign(false));
    }
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsStartingCampaign(false));
    throw new Error(errorMessage);
  }
};

export const getCampaignEmailAnalyticsAction =
  (id: string) => async (dispatch: any) => {
    dispatch(setIsFetchingCampaignAnalytics(true));

    try {
      const res = await getRequest({
        url: `${API_URLS?.campaignEmailAnalytics}/${id}`,
        params: null,
      });

      dispatch(setIsFetchingCampaignAnalytics(false));

      return {
        success: true,
        data: res?.data?.mailersend_response?.body?.data?.stats || [],
      };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsFetchingCampaignAnalytics(false));
    }
  };

export const getKonvasCampaignTemplatesAction = () => async (dispatch: any) => {
  try {
    dispatch(setIsFetchingKonvasCampaignTemplates(true));

    const response = await fetch(API_URLS?.getKonvasCampaignTemplates);

    await handleFetchAPIError(response);

    const resp = await response.json();

    console.log("konvas-campaign-templates-success-response", resp?.templates);

    dispatch(getKonvasCampaignTemplatesSuccess(resp?.templates));
    dispatch(setIsFetchingKonvasCampaignTemplates(false));

    return { success: true, templates: resp?.templates };
  } catch (err) {
    const errorMessage = getRequestError(err);
    dispatch(setAlert(true, "error", errorMessage));

    dispatch(setIsFetchingKonvasCampaignTemplates(false));
  }
};
export const getKonvasCampaignTemplateByIdAction =
  (templateId: number) => async (dispatch: any) => {
    try {
      dispatch(setIsFetchingKonvasCampaignTemplateById(true));

      const response = await fetch(
        API_URLS?.getKonvasCampaignTemplateById(templateId)
      );

      await handleFetchAPIError(response);

      const resp = await response.json();

      console.log(
        "konvas-campaign-template-by-id-success-response",
        resp?.data
      );

      dispatch(getKonvasCampaignTemplateByIdSuccess(resp?.data));
      dispatch(setIsFetchingKonvasCampaignTemplateById(false));

      return { success: true, templates: resp?.data };
    } catch (err) {
      const errorMessage = getRequestError(err);
      dispatch(setAlert(true, "error", errorMessage));

      dispatch(setIsFetchingKonvasCampaignTemplateById(false));
    }
  };


