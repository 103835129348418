import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend
} from 'recharts';
import moment from 'moment';

interface DashboardChartProps {
    analyticsData?: {
        status: boolean;
        data: {
            businessSlug: string;
            totalCampaigns: number;
            successfullyFetchedCampaigns: number;
            periodStart: string;
            periodEnd: string;
            totals: {
                queued: number;
                sent: number;
                delivered: number;
                opened: number;
                opened_unique: number;
            };
            rates: {
                deliveryRate: number;
                openRate: number;
                bounceRate: number;
            };
        };
    };
    isLoading?: boolean;
    error?: string | null;
    onRetry?: () => void;
}

const DashboardChart: React.FC<DashboardChartProps> = ({
    analyticsData,
    isLoading,
    error,
    onRetry
}) => {
    const chartData = React.useMemo(() => {
        if (!analyticsData?.data) {
            console.log('No analytics data available');
            return [];
        }

        console.log('Processing analytics data:', analyticsData.data);

        const data = analyticsData.data;
        const rates = data.rates || {};
        const totals = data.totals || {};

        if (data.totalCampaigns === 0) {
            return [];
        }

        // Ensure we have numerical values with fallbacks
        const openRate = Number((rates.openRate || 0).toFixed(2));
        const deliveryRate = Number((rates.deliveryRate || 0).toFixed(2));
        const totalOpens = totals.opened || 0;
        const uniqueOpens = totals.opened_unique || 0;
        const sent = totals.sent || 0;
        const delivered = totals.delivered || 0;

        console.log('Transformed chart data:', {
            openRate,
            deliveryRate,
            totalOpens,
            uniqueOpens,
            sent,
            delivered
        });

        return [{
            name: moment(data.periodStart).format('MMM DD') + ' - ' + moment(data.periodEnd).format('MMM DD'),
            'Open Rate': openRate,
            'Delivery Rate': deliveryRate,
            'Total Opens': totalOpens,
            'Unique Opens': uniqueOpens,
            'Emails Sent': sent,
            'Emails Delivered': delivered
        }];
    }, [analyticsData]);

    if (isLoading) {
        return (
            <div style={{ width: '100%', height: '400px' }} className="chart-container loading">
                <div className="loading-content">
                    <div className="loading-spinner"></div>
                    <div className="loading-text">Loading analytics data...</div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div style={{ width: '100%', height: '400px' }} className="chart-container error">
                <div className="error-content">
                    <div className="error-message">{error}</div>
                    {onRetry && (
                        <button
                            onClick={onRetry}
                            className="retry-button"
                        >
                            Retry
                        </button>
                    )}
                </div>
            </div>
        );
    }

    if (!chartData.length) {
        return (
            <div style={{ width: '100%', height: '400px' }} className="chart-container empty">
                <div className="empty-message">
                    No data available for the selected period
                </div>
            </div>
        );
    }

    console.log('Rendering chart with data:', chartData);

    return (
        <div style={{ width: '100%', height: '400px', background: 'white', padding: '20px', borderRadius: '8px' }}>
            <ResponsiveContainer>
                <BarChart
                    data={chartData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="name" />
                    <YAxis
                        yAxisId="percentage"
                        orientation="left"
                        tickFormatter={(value) => `${value}%`}
                        domain={[0, 100]}
                    />
                    <YAxis
                        yAxisId="count"
                        orientation="right"
                        domain={[0, 'auto']}
                    />
                    <Tooltip
                        content={({ active, payload }) => {
                            if (active && payload && payload.length) {
                                return (
                                    <div style={{
                                        background: 'white',
                                        padding: '12px',
                                        border: '1px solid #e5e7eb',
                                        borderRadius: '8px',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                    }}>
                                        <p style={{ fontWeight: 600, marginBottom: '8px' }}>
                                            {payload[0].payload.name}
                                        </p>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                            {payload.map((entry: any, index: number) => (
                                                <p
                                                    key={index}
                                                    style={{
                                                        color: entry.color,
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    {entry.name}: {entry.value}
                                                    {entry.name.includes('Rate') ? '%' : ''}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                );
                            }
                            return null;
                        }}
                    />
                    <Legend />
                    <Bar
                        yAxisId="percentage"
                        dataKey="Open Rate"
                        fill="#2563EB"
                        radius={[4, 4, 0, 0]}
                    />
                    <Bar
                        yAxisId="percentage"
                        dataKey="Delivery Rate"
                        fill="#10B981"
                        radius={[4, 4, 0, 0]}
                    />
                    <Bar
                        yAxisId="count"
                        dataKey="Unique Opens"
                        fill="#6366F1"
                        radius={[4, 4, 0, 0]}
                    />
                    <Bar
                        yAxisId="count"
                        dataKey="Total Opens"
                        fill="#8B5CF6"
                        radius={[4, 4, 0, 0]}
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default DashboardChart;