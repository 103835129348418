import axios from "axios";

import { getUserDetails, useLocalStorage } from "../utils/functions";
import { APP_USER } from "../utils/constants";

const companySlug = getUserDetails()?.company_slug;

const { REACT_APP_AI_API_URL, REACT_APP_NODE_API_URL } = process.env;

export const API_URLS = {
  createUser: "/register",
  createShopifyUser: "/auths/register_shopify",
  loginUser: "/login",
  shopifyLogin: "/auths/shopify_login",
  googleLogin: "/auths/google_auth",
  googleRegister: "/auths/google_reg",
  logoutUser: "/logout",
  sendOnboardingQuestions: "/auths/onboard",
  updateProfile: `/settings/update_profile`,
  brandUpdate: `/brands/brand_asset_info/${companySlug}`,
  subscription: `/subscription/user`,
  plans: `/subscription/all_plans`,
  upgradePlan: `/subscription/payment_success`,
  changePassword: `/settings/change_password`,
  aiSyncToVectorDB: `https://konvas.ai/ai/api/integrations/${companySlug}`,
  getOpenRates: `/campaigns/analytics`,
  aiSyncResponse: `/shopify_sync/${companySlug}`,
  getShopifyDetails: "/brands/shopify_details/",
  getSetupProgress: `/brands/setup_progress/${companySlug}`,
  getDailyVideo: `/daily-report-videos/${companySlug}`,
  getNoDailyVideo: `/daily-report-videos/nodata.mp4`,
  getHomeInsights: `/insights/top_insights/${companySlug}`,
  getChartData: `/campaigns/dashboard_analytics/${companySlug}`,
  getSegments: `/segments/all/${companySlug}`,
  getSegmentById: `/segments/get_by_id`,
  generateAISegment: `${process.env.REACT_APP_AI_API_URL}/api/segments/${companySlug}`,
  generateCustomSegment: `${process.env.REACT_APP_AI_API_URL}/api/segments/custom/${companySlug}`,
  addSegment: "/segments/add",
  deleteSegment: `/segments/delete`,
  getInsights: `/insights/all/${companySlug}`,
  getInsightById: `/insights/get_by_id`,
  generateAIInsight: `${process.env.REACT_APP_AI_API_URL}/api/insights/${companySlug}`,
  generateCustomInsight: `${process.env.REACT_APP_AI_API_URL}/api/insights/custom/${companySlug}`,
  addInsight: "/insights/add",
  updateInsightStatus: `/insights/updateStatus`,
  deleteInsight: `/insights/delete`,
  getCampaigns: `/campaigns/all/${companySlug}`,
  uniqueOpenRates: `/campaigns/analytics`,
  getCampaignById: `/campaigns/get_by_id`,
  getPersonalizedCampaignById: `/campaigns/get_personalized_emails`,
  getKonvasCampaignTemplates: `${process.env.REACT_APP_API_URL}/campaigns/email_templates`,
  getKonvasCampaignTemplateById: (templateId: number) =>
    `${process.env.REACT_APP_API_URL}/config/email_template_details/${templateId}`,
  // generateAICampaign: `http://localhost:3030/api/campaigns/create-ai-generated/${companySlug}`,
  generateAICampaign: `${process.env.REACT_APP_AI_API_URL}/api/campaigns/create-ai-generated/${companySlug}`,
  // generateAICustomCampaign: `http://localhost:3030/api/campaigns/custom`,
  generateAICustomCampaign: `${process.env.REACT_APP_AI_API_URL}/api/campaigns/custom`,
  // generateAISegmentCampaign: `http://localhost:3030/api/campaigns/segment`,
  generateAISegmentCampaign: `${process.env.REACT_APP_AI_API_URL}/api/campaigns/segment`,
  addCampaignForPersonalization: `/campaigns/add_personalized`,
  addCustomCampaign: `/campaigns/add_by_custom`,
  addSegmentCampaign: `/campaigns/add_by_segment`,
  deleteCampaign: `/campaigns/delete`,
  // rewriteCampaignMsg: `http://localhost:3030/api/campaigns/rewrite-message-body`,
  rewriteCampaignMsg: `${process.env.REACT_APP_AI_API_URL}/api/campaigns/rewrite-message-body`,
  rewriteUnsavedCampaignBody: `${process.env.REACT_APP_AI_API_URL}/api/campaigns/rewrite-unsaved-campaign-body`,
  saveCampaignEmailChannel: `/campaigns/save_email_channel`,
  updateCampaign: `/campaigns/update`,
  endCampaign: `/campaigns/end`,
  continueCampaign: "/campaigns/continue",
  pauseCampaign: `/campaigns/pause`,
  scheduleCampaign: `/campaigns/schedule`,
  // sendCampaignEmail: `/campaigns/bulk_email`,
  sendCampaignEmail: `/campaigns/bulk_batch`,
  campaignEmailAnalytics: `/campaigns/analytics`,
  getCustomers: `/customers/all/${companySlug}`,
  getCustomerById: `/customers/get_by_id`,
  getCustomerInsight: `${process.env.REACT_APP_AI_API_URL}/api/insights/unique-customer-insight`,

  getBrandData: `/brands/get_ai_data/${companySlug}`,
  addBrandData: `/brands/add_ai_data`,
  updateBrandData: `/brands/update_ai_data`,
  updateBrandTones: `/brands/update_ai_tone`,
  updateBrandStoreData: `/brands/update_ai_training_data`,
  updateBrandWritingApproach: `/brands/update_writing_approach`,
  updateBrandFiles: `/brands/update_ai_files`,
  updateBrandSummary: `/brands/update_ai_summary`,
  // getBrandSummary: `http://localhost:3030/api/business/brand-summary/${companySlug}`,
  getBrandSummary: `${REACT_APP_AI_API_URL}/api/business/brand-summary/${companySlug}`,

  // getKlaviyoAuthUrl: `http://localhost:5000/api/klaviyo/getAuthUrl/?business_slug=${companySlug}`,
  // verifyKlaviyoAuthCode: `http://localhost:5000/api/klaviyo/retrieveTokens`,
  // getKlaviyoCampaignById: (campaignId: string) =>
  //   `http://localhost:5000/api/klaviyo/campaigns/${campaignId}`,
  // createKlaviyoCampaign: `http://localhost:5000/api/klaviyo/send-campaign`,
  // scheduleKlaviyoCampaignSend: `http://localhost:5000/api/klaviyo/campaigns/send`,
  // assignTemplateToKlaviyoCampaign: `http://localhost:5000/api/klaviyo/templates/assign`,
  // getKlaviyoTemplates: `http://localhost:5000/api/klaviyo/templates?business_slug=${companySlug}`,
  // createKlaviyoTemplate: `http://localhost:5000/api/klaviyo/templates/create`,
  // estimateRecipients: (campaignId: string) =>
  //   `http://localhost:5000/api/klaviyo/campaigns/${campaignId}/estimate-recipients`,
  // renderKlaviyoTemplate: (templateId: string) =>
  //   `http://localhost:5000/api/klaviyo/templates/${templateId}/render`,

  getKlaviyoAuthUrl: `${REACT_APP_NODE_API_URL}/api/klaviyo/getAuthUrl/?business_slug=${companySlug}`,
  verifyKlaviyoAuthCode: `${REACT_APP_NODE_API_URL}/api/klaviyo/retrieveTokens`,
  getKlaviyoCampaignById: (campaignId: string) =>
    `${REACT_APP_NODE_API_URL}/api/klaviyo/campaigns/${campaignId}`,
  getCampaignOpenRateAnalytics: `${REACT_APP_NODE_API_URL}/campaigns/open-rate-analytics/${companySlug}/`,
  createKlaviyoCampaign: `${REACT_APP_NODE_API_URL}/api/klaviyo/send-campaign`,
  scheduleKlaviyoCampaignSend: `${REACT_APP_NODE_API_URL}/api/klaviyo/campaigns/send`,
  assignTemplateToKlaviyoCampaign: `${REACT_APP_NODE_API_URL}/api/klaviyo/templates/assign`,
  getKlaviyoTemplates: `${REACT_APP_NODE_API_URL}/api/klaviyo/templates?business_slug=${companySlug}`,
  createKlaviyoTemplate: `${REACT_APP_NODE_API_URL}/api/klaviyo/templates/create`,
  estimateRecipients: (campaignId: string) =>
    `${REACT_APP_NODE_API_URL}/api/klaviyo/campaigns/${campaignId}/estimate-recipients`,
  renderKlaviyoTemplate: (templateId: string) =>
    `${REACT_APP_NODE_API_URL}/api/klaviyo/templates/${templateId}/render`,
};

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
  },
  transformRequest: [(data) => data],
  paramsSerializer: {
    serialize: (params) => {
      const searchParams = new URLSearchParams();
      Object.keys(params).forEach((key) => {
        if (params[key] !== null && params[key] !== undefined) {
          searchParams.append(key, params[key]);
        }
      });
      return searchParams.toString();
    },
  },
});

export const AxiosAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
  },
});

// Request interceptors for API calls
API.interceptors.request.use(
  async (config: any) => {
    const tokenData = getUserDetails();

    config.headers = {
      Authorization: tokenData
        ? `${tokenData.token_type} ${tokenData.token}`
        : ``,
      "X-API-KEY": process.env.REACT_APP_API_KEY,
    };
    if (config.method === "get") {
      config.params = {
        ...config.params,
        _t: Date.now(),
      };
    }
    if (config.params) {
      const { _t, ...cleanParams } = config.params;
      config.params = cleanParams;
    }

    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    if (response?.status === 401) {
      const isSessionExpired =
        response.data?.message?.toLowerCase().includes("token") ||
        response.data?.message?.toLowerCase().includes("session") ||
        response.data?.message?.toLowerCase().includes("unauthorized");

      if (isSessionExpired) {
        useLocalStorage.remove(APP_USER);

        window.location.href = "/login";
      }
    }

    return Promise.reject(error);
  }
);

// Request interceptors for API calls
AxiosAPI.interceptors.request.use(
  async (config: any) => {
    const tokenData = getUserDetails();

    config.headers = {
      Authorization: tokenData
        ? `${tokenData.token_type} ${tokenData.token}`
        : ``,
      "X-API-KEY": process.env.REACT_APP_API_KEY,
    };
    if (config.method === "get") {
      config.params = {
        ...config.params,
        _t: Date.now(),
      };
    }
    if (config.params) {
      const { _t, ...cleanParams } = config.params;
      config.params = cleanParams;
    }

    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

AxiosAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    // console.log("response:", response);

    const isSessionExpired = response?.status === 401;

    if (isSessionExpired) {
      useLocalStorage.remove(APP_USER);

      window.location.href = "/login";
    }

    // if (response?.status === 401) {
    //   const isSessionExpired =
    //     response.data?.message?.toLowerCase().includes("token") ||
    //     response.data?.message?.toLowerCase().includes("session") ||
    //     response.data?.message?.toLowerCase().includes("unauthorized");

    //   if (isSessionExpired) {
    //     useLocalStorage.remove(APP_USER);

    //     window.location.href = "/login";
    //   }
    // }

    return Promise.reject(error);
  }
);

type PostRequestProps = {
  url: string;
  params: any;
  data: any;
  formData: any;
};
export const postRequest = async ({
  url,
  params,
  data,
  formData,
}: PostRequestProps) => {
  const requestResponse = await AxiosAPI({
    headers: {
      "Content-Type": formData ? "multipart/form-data" : "application/json",
    },
    url,
    method: "post",
    params,
    data,
  });

  return requestResponse;
};

type GetRequestProps = {
  url: string;
  params: any;
};
export const getRequest = async ({ url, params }: GetRequestProps) => {
  const requestResponse = await API({
    url,
    method: "get",
    params,
  });

  return requestResponse;
};

type PatchRequestProps = {
  url: string;
  params: any;
  data: any;
};
export const patchRequest = async ({
  url,
  params,
  data,
}: PatchRequestProps) => {
  const requestResponse = await API({
    url,
    method: "patch",
    params,
    data,
  });

  return requestResponse;
};

type DeleteRequestProps = {
  url: string;
  params: any;
  data: any;
};
export const deleteRequest = async ({
  url,
  params,
  data,
}: DeleteRequestProps) => {
  const requestResponse = await API({
    url,
    method: "delete",
    params,
    data,
  });

  return requestResponse;
};
