import StudioEditor from "@grapesjs/studio-sdk/react";
import "@grapesjs/studio-sdk/style";

type Props = {
  content: string;
  onReady: any;
  businessSlug: string;
};

console.log("grapejs-project-id:", process.env.REACT_APP_GRAPESJS_PROJECT_ID);

const GrapeJsEditor = ({ content, onReady, businessSlug }: Props) => {
  return (
    // <div style={{ display: "flex", flexDirection: "column", height: "100dvh" }}>
    <StudioEditor
      style={{ flexGrow: 1 }}
      options={{
        gjsOptions: { storageManager: false },
        licenseKey: process.env.REACT_APP_GRAPESJS_LICENSE_KEY,
        onReady,
        theme: "light",
        project: {
          type: "web",
          id: process.env.REACT_APP_GRAPESJS_PROJECT_ID,
          default: {
            pages: [{ name: "Home", component: content }],
          },
        },

        identity: {
          id: businessSlug,
        },

        assets: {
          storageType: "cloud",
        },

        layout: {
          default: {
            type: "row",
            style: { height: "100%", color: "#fff!important" },
            children: [
              // { type: "sidebarLeft" },
              { type: "canvasSidebarTop" },
              { type: "sidebarRight" },
            ],
          },
          responsive: {
            // Studio will switch the layout when the editor container width is below 1000px.
            1000: {
              type: "row",
              style: { height: "100%" },
              // children: [{ type: "sidebarLeft" }, { type: "canvas" }],
              children: [{ type: "sidebarRight" }, { type: "canvas" }],
            },
            600: {
              type: "column",
              style: { height: "100%" },
              children: [{ type: "canvas" }, { type: "row", children: "Text" }],
            },
          },
        },
      }}
    />
    // </div>
  );
};

export default GrapeJsEditor;
