import { useState } from "react";

import classNames from "classnames";

import Button from "../../components/button";
import CampaignPersonalizedModal from "../../components/modal/campaign-personalized-modal";
import CampaignSenderConfirmationModal from "../../components/modal/campaign-sender-confirmation.modal";
import DropDown from "../../components/dropdown";

import Icon from "../../assets/svg";

type Props = {
  campaignData: any;
  contentOptions: { label: string; action: () => void }[];
  campaignSender: string;
  isStartingCampaign: boolean;
  startCampaign: () => void;
  setIsCampaignScheduleModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsConfirmationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pauseCampaign: () => void;
  continueCampaign: () => void;
  isPausingCampaign: boolean;
  isContinuingCampaign: boolean;
  isEndingCampaign: boolean;
  endCampaign: () => void;
};

const CampaignFooter = ({
  campaignData,
  contentOptions,
  campaignSender,
  isStartingCampaign,
  startCampaign,
  setIsCampaignScheduleModalOpen,
  setIsConfirmationModalOpen,
  pauseCampaign,
  continueCampaign,
  isPausingCampaign,
  isContinuingCampaign,
  isEndingCampaign,
  endCampaign,
}: Props) => {
  const [isCampaignPersonalizedModal, setIsCampaignPersonalizedModal] =
    useState(false);
  const [
    isCampaignSenderConfirmationModal,
    setIsCampaignSenderConfirmationModal,
  ] = useState(false);
  const campaignStatus = campaignData.status;
  const isPaused = campaignData.active_status === "paused";
  return (
    <div className="footer">
      <div className="top">
        {campaignStatus === "draft" && (
          <div
            className="schedule"
            onClick={() => setIsCampaignScheduleModalOpen(true)}
          >
            <Icon name="clock" />
            Schedule
          </div>
        )}
        {campaignStatus === "active" && (
          <div
            className={classNames("campaign_active_tag", { paused: isPaused })}
          >
            <Icon name={isPaused ? "pause" : "clock"} />
            Campaign {isPaused ? "Paused" : "Ongoing"}
          </div>
        )}

        <div className="side">
          <div
            className="icon"
            onClick={() => setIsConfirmationModalOpen(true)}
          >
            <Icon name="trash" />
          </div>

          <DropDown toggler={<Icon name="dotsV" />}>
            {contentOptions?.map((item: any, i: any) => (
              <div key={i} className="dropdown-item" onClick={item?.action}>
                {item?.label}
              </div>
            ))}
          </DropDown>

          {campaignSender === "Klaviyo" && (
            <Button
              className="klaviyo_preview"
              text="Klaviyo Preview"
              disabled={campaignData?.klaviyo_campaign_id ? false : true}
              onClick={() => { }}
            />
          )}

          {(campaignStatus === "draft" || campaignStatus === "scheduled") && (
            <Button
              text="Start Campaign"
              onClick={() => {
                setIsCampaignSenderConfirmationModal(true);
              }}
              loading={isStartingCampaign}
            />
          )}
          {campaignStatus === "active" && (
            <div className="active_campaign_btn">
              <Button
                text={isPaused ? "Continue" : "Pause"}
                className={classNames("pause", { continue: isPaused })}
                onClick={isPaused ? continueCampaign : pauseCampaign}
                loading={
                  isPausingCampaign
                    ? isPausingCampaign
                    : isContinuingCampaign
                      ? isContinuingCampaign
                      : false
                }
              />
              <Button
                text={"End"}
                className="end"
                onClick={endCampaign}
                loading={isEndingCampaign}
              />
            </div>
          )}
        </div>
      </div>
      {campaignStatus === "draft" && (
        <div className="bottom_text">
          <ul>

            <li>
              To know more about how we generate personalized campaigns,{" "}
              <span onClick={() => setIsCampaignPersonalizedModal(true)}>
                Read More
              </span>
            </li>
          </ul>
          <CampaignPersonalizedModal
            isOpen={isCampaignPersonalizedModal}
            close={() => setIsCampaignPersonalizedModal(false)}
          />
          <CampaignSenderConfirmationModal
            isOpen={isCampaignSenderConfirmationModal}
            close={() => setIsCampaignSenderConfirmationModal(false)}
            campaignSender={campaignSender}
            action={startCampaign}
          />
        </div>
      )}
    </div>
  );
};

export default CampaignFooter;
