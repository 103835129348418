import React from "react";
import { FaEllipsisH, FaTimes } from "react-icons/fa";
// import Button from "../button";
// import CustomDropdown from "../custom-dropdown";
import { useSelector } from "react-redux";
import Loader from "../loader";
// import { useForm } from "react-hook-form";
// import FormInput from "../form-input";
// import { convertEscapedStringToHTML, textFormValidation } from "../../utils/functions";
// import OutlineButton from "../outline-button";

type AnotherDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  generatedData: any;
  brandData: any;
  onSubmit: (payload: any) => void;
};

// type FormInputs = {
//     name: string;
// }

const GeneraedCampaignDialog = ({
  isOpen,
  onClose,
  onSubmit,
  generatedData,
  brandData,
}: AnotherDialogProps) => {
  // const templatesOption = ["Template1", "Template2"];

  const { isSavingCampaign } = useSelector(
    (state: any) => state.campaignsSlice
  );

  // const [iframeSrc, setIframeSrc] = useState<string | null>(null);

  // const [selectedTemplate, setSelectedTemplate] = useState(templatesOption[0]);
  // const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  // const [isAnotherDialogOpen, setAnotherDialogOpen] = useState(false);

  let ai_tones: string[];
  let writing_approach: string = "";

  if (brandData?.store_tones) ai_tones = JSON.parse(brandData?.store_tones);
  if (brandData?.writing_approach)
    writing_approach = brandData?.writing_approach;

  // const handleConfirmCampaignClick = () => {
  //     setConfirmModalOpen(true);
  // };

  // const handleConfirmClose = () => {
  //     setConfirmModalOpen(false);
  // };

  // const handleConfirmCampaign = () => {
  //     setConfirmModalOpen(false);
  //     setAnotherDialogOpen(true);
  // };

  const { user } = useSelector((state: any) => state.userSlice);
  // const [isSubmitting, setIsSubmitting] = useState(false);

  // const {
  //     register,
  //     handleSubmit,
  //     formState: { errors, isValid, isDirty },
  // } = useForm<FormInputs>({
  //     mode: "onChange",
  //     defaultValues: {
  //         name: user?.user_name,
  //     },
  // })
  // const handleSelectedTemplate = (template: string) => {
  //     setSelectedTemplate(template);
  //     console.log("Selected font:", template);
  // };

  const handleSaveCampaign = (status: string) => {
    try {
      const template_id = Number(localStorage.getItem("tmplt_id"));

      let payload: any = {
        business_slug: user?.company_slug,
        campaign_title: generatedData.campaign_title,
        ai_tones,
        writing_approach,
        ai_details: `<p><strong>RATIONALE / MARKETING STRATEGY:</strong> ${generatedData.rationale}</p><p><strong>TARGET AUDIENCE:</strong> ${generatedData.target_audience}</p><p><strong>METRICS:</strong> ${generatedData.metrics}</p>`,
        campaign_goal: generatedData?.campaign_goal,
        campaign_subject: generatedData.campaign_subject,
        campaign_body: generatedData.campaign_body,
        campaign_recipients: generatedData.campaign_recipients,
        campaign_duration: generatedData.campaign_duration,
        start_date: generatedData.start_date,
        end_date: generatedData.end_date,
        created_context: generatedData.created_context,
        template_id,
        status,
      };
      // if (segment?.value) payload.segment_id = segment?.value;

      onSubmit({
        // segment_id: segment?.value,
        payload,
      });
    } catch (error) {
      onClose();
    }
  };

  React.useEffect(() => {
    const dialog = document.querySelector(
      ".generated-campaign_modal"
    ) as HTMLDialogElement;
    if (dialog) {
      if (isOpen) {
        dialog.showModal();
        handleSaveCampaign("draft");
      } else {
        dialog.close();
      }
    }
  }, [isOpen]);

  // React.useEffect(() => {
  //     const confirmDialog = document.querySelector(".confirm_modal") as HTMLDialogElement;
  //     if (confirmDialog) {
  //         if (isConfirmModalOpen) {
  //             confirmDialog.showModal();
  //         } else {
  //             confirmDialog.close();
  //         }
  //     }
  // }, [isConfirmModalOpen]);

  // useEffect(() => {
  //     const htmlTemplate = convertEscapedStringToHTML(generatedData?.campaign_body);

  //     const blob = new Blob([htmlTemplate], { type: "text/html" });
  //     const blobUrl = URL.createObjectURL(blob);

  //     setIframeSrc(blobUrl);

  //     return () => {
  //         if (iframeSrc) {
  //             URL.revokeObjectURL(iframeSrc);
  //         }
  //     };
  // }, [isOpen, generatedData]);

  // const rationaleContent = generatedData?.rationale
  // ?.replace(/:/g, ":\n")
  // ?.replace(".", ".\n\n");

  return (
    <>
      <dialog className="generated-campaign_modal">
        <div className="template-modal_container">
          <div className="modal-header">
            <div className="template-tags-container">
              <div className="tags-container">
                <p className="preview-tags-text">Save Draft</p>
              </div>
            </div>
            <div className="action-icons">
              <FaEllipsisH className="action-icon" />
              <FaTimes className="close-icon" onClick={onClose} />
            </div>
          </div>

          <div className="saving">
            <Loader text="Saving created campaign as draft..." />
          </div>

          {/* <div className="gen_dialog-body">
                        <div className="generated_campaign_heading">
                            <h4>CAMPAIGN TITLE</h4>
                            <p> {generatedData?.campaign_title}</p>
                        </div>
                        <div className="recipient_sender-container">
                            
                            <div>
                                <p className="gen_sender">CAMPAIGN GOAL</p>
                                <p className="gen_sender-orange">{generatedData?.campaign_goal}</p>
                            </div>
                            <div>
                                <p className="gen_sender">NO OF CUSTOMERS</p>
                                <p className="number_of-customers">{Number(
                          generatedData?.campaign_recipients?.length
                        ).toLocaleString("en-US")}</p>
                            </div>
                        </div>
                        <div>
                            <p className="gen_email-header">AGENTS ANALYSIS  AND INSIGHTS</p>
                            <p className="gen_email-body">{rationaleContent}</p>
                        </div>
                        <div className="custom-dropdown_container">
                            <CustomDropdown
                                options={templatesOption}
                                defaultSelected={selectedTemplate}
                                onSelect={handleSelectedTemplate}
                                dropdownColor="#2563EB"
                                textColor="#fff"
                            />
                        </div>
                        <div className="preview-template_body" style={{ height: "60vh", overflow: "hidden" }}>
                            {iframeSrc && (
                                <iframe
                                    src={iframeSrc}
                                    title="Template Preview"
                                    className="template-iframe"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        border: "none",
                                    }}
                                ></iframe>
                            )}
                        </div>
                        <div className="gen_dialog-footer">
                            <p className="gen-footer_one">The highlighted       parts contains the personalized contents that are unique to each customer. </p>

                            <p className="gen-footer_two">For additional context to rewrite this campaign message, use below box to give instructions.</p>

                            <FormInput
                                type="text"
                                placeholder="Prompt AI"
                                readOnly={isSubmitting}
                                errorMessage={errors?.name?.message}
                                inputRef={{
                                    ...register("name", textFormValidation(true)),
                                }}
                                rightIcon={<FaArrowAltCircleRight className="icon_send" />}
                                iconClick={() => console.log("Search action triggered!")}
                            />
                        </div>



                        <div className="generated_button-container">
                            <Button text="Accept" 
                            // onClick={handleConfirmCampaignClick}
                            disabled={isSavingCampaign}
                            btnStyle={{ height:'50px'  }} 
                            />
                            <Button 
                            text="Save as draft" 
                              //  onClick={onClose}  
                               onClick={() => handleSaveCampaign("draft")} 
                               loading={isSavingCampaign}
                               disabled={isSavingCampaign} 
                               btnStyle={{ backgroundColor: "#565C66", border: "none", height:'50px' }} />
                            <Button 
                               text="Decline"
                               onClick={onClose}
                               disabled={isSavingCampaign} 
                               btnStyle={{ backgroundColor: "#EB4925", border: "none", height:'50px'  }} 
                               />

                        </div>
                    </div> */}
        </div>
      </dialog>

      {/* <dialog className="confirm_modal">
                <div className="template-modal_container">
                    <div className="modal-header">
                        <div className="template-tags-container">
                            <div className="tags-container">
                                <p className="preview-tags-text">TEMPLATE CHANGE</p>
                            </div>
                        </div>
                        <div className="action-icons">
                            <FaEllipsisH className="action-icon" />
                            <FaTimes className="close-icon" onClick={handleConfirmClose} />
                        </div>
                    </div>
                    <div className="dialog_body">
                        <p>Campaign generation accepted</p>
                        <div className="dialog_buttons">
                            <OutlineButton text="Cancel" onClick={handleConfirmClose} />
                            <Button text="Confirm" onClick={handleConfirmCampaign} />
                        </div>
                    </div>
                </div>
            </dialog> */}
    </>
  );
};

export default GeneraedCampaignDialog;
