import React, { useState } from "react";
import classNames from "classnames";

import Icon from "../../assets/svg";

import { getBrandToneClassNames } from "../../utils/functions";

type Props = {
  tags: Array<{ tag: string }>;
  setSelectedTags: React.Dispatch<React.SetStateAction<{ tag: string }[]>>;
  setInitialTags: React.Dispatch<React.SetStateAction<{ tag: string }[]>>;
};

const TagSelect = ({ tags, setSelectedTags, setInitialTags }: Props) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedtagName] = useState("Select tags");

  const handleSelectTag = (tagName: string) => {
    setSelectedTags((prev: any) => {
      if (prev?.length > 1) return prev;
      const isTagAlreadyInSelectTags = prev.some(
        (data: { tag: string }) => data.tag === tagName
      );
      if (isTagAlreadyInSelectTags) return prev;
      setInitialTags((prev) =>
        prev?.filter((data: { tag: string }) => data.tag !== tagName)
      );
      return Array.isArray(prev)
        ? [...prev, { tag: tagName }]
        : [{ tag: tagName }];
    });
    setShowDropDown(false);
  };

  return (
    <div className="tag_select">
      <div
        className="tag_select_input"
        onClick={() => setShowDropDown((prev) => !prev)}
      >
        <p>{selectedtagName}</p>
        <Icon name="chevronDown" />
      </div>
      {showDropDown ? (
        <div className="tag_select_dropdown">
          <div className="brand_tags no-border flex-column">
            {tags.map((data, index) => (
              <div
                className={classNames(
                  "tag",
                  getBrandToneClassNames(index, data.tag)
                )}
                // key={data.tag}
                onClick={() => handleSelectTag(data.tag)}
              >
                <div
                  className={classNames(
                    "dot",
                    getBrandToneClassNames(index, data.tag)
                  )}
                ></div>
                <p
                  className={classNames(
                    getBrandToneClassNames(index, data.tag)
                  )}
                >
                  {data.tag}
                </p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TagSelect;
