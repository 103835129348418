import Icon from "../assets/svg";

import {
  goBackAction,
  printerAction,
  openExternalLinkAction,
} from "../utils/functions";

type Props = {
  goBack?: boolean;
  goBackOnClick?: () => void;
  title?: string;
  leftSide?: React.ReactNode;
  printer?: boolean;
  externalLink?: string;
  rightSide?: React.ReactNode;
};

const SectionHeader = ({
  goBack,
  goBackOnClick,
  title,
  leftSide,
  printer,
  externalLink,
  rightSide,
}: Props) => {
  return (
    <div className="section_header_container">
      <div className="side">
        {goBack && (
          <div
            className="icon"
            onClick={goBackOnClick ? goBackOnClick : goBackAction}
          >
            <Icon name="arrowBack" />
          </div>
        )}
        {title && <p className="title">{title}</p>}
        {leftSide}
      </div>

      <div className="side">
        {/* {printer && (
          <div className="icon" onClick={printerAction}>
            <Icon name="printer" />
          </div>
        )} */}
        {externalLink && (
          <div
            className="icon"
            onClick={() => openExternalLinkAction(externalLink)}
          >
            <Icon name="externalLink" />
          </div>
        )}
        {rightSide}
      </div>
    </div>
  );
};

export default SectionHeader;
