import React from 'react';
import { useHistory } from 'react-router-dom';
import SectionHeader from './section-header';


const CampaignNotFound = () => {
    const history = useHistory();

    return (
        <div className="campaign_details is_visible">
            <SectionHeader
                goBack={true}
                goBackOnClick={() => history.push('/campaigns')}
                title="Campaign Not Found"
            />

            <div className="flex flex-col items-center justify-center p-8 space-y-4">
                <div className="w-16 h-16 text-gray-400">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>

                <div className="text-center">
                    <h3 className="text-lg font-medium text-gray-900">Campaign Not Found</h3>
                    <p className="mt-1 text-sm text-gray-500">
                        The campaign you're looking for doesn't exist or has been deleted.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CampaignNotFound;