import moment from "moment";

import { APP_USER } from "./constants";
import {
  brandTags,
  brandTagStyleClassNames,
  tag_style_four,
  tag_style_one,
  tag_style_three,
  tag_style_two,
} from "./appdata";

// Local storage operations
export const useLocalStorage = {
  set: (key: string, data: any) => {
    let stringifiedData = JSON.stringify(data);
    localStorage.setItem(key, stringifiedData);
  },

  get: (key: string) => {
    const data: any = JSON.parse(localStorage.getItem(key)!);

    if (!data) {
      return null;
    }
    return data;
  },

  remove: (key: string) => {
    localStorage.removeItem(key);
  },

  clear: () => {
    localStorage.clear();
  },
};

export const getUserDetails = () => {
  const user = useLocalStorage.get(APP_USER);

  return user ? user : null;
};

export const handleFetchAPIError = async (res: Response) => {
  if (!res.ok) {
    // Attempt to parse error details from the response body
    let errorDetails: string;
    try {
      const errorData = await res.json();
      errorDetails =
        errorData.message || errorData.error || `Status: ${res.status}`;
    } catch {
      // Fallback if the response body is not JSON
      errorDetails = `HTTP error! Status: ${res.statusText}`;
    }

    throw new Error(`${errorDetails}`);
  }
};

export const getRequestError = (error: any) => {
  const { response, message } = error;
  console.log("error:", error);

  const isSessionExpired = response?.status === 401;

  if (isSessionExpired) {
    // logoutUser();
    return "Your session has expired. Please log in again.";
  }

  if (response?.data?.errors?.[0]) {
    return response.data.errors[0].message;
  }

  if (response?.data?.message) {
    return response.data.message;
  }

  if (response?.data?.error) {
    return response.data.error;
  }

  if (message) {
    return message;
  }

  return "There might be a problem with your internet connection. Please check and try again.";
};

export const logoutUser = () => {
  useLocalStorage.remove(APP_USER);
  window.location.reload();
};

export const goBackAction = () => {
  window.history.go(-1);
};

export const printerAction = () => {
  window.print();
};

export const openExternalLinkAction = (url: string) => {
  window.open(url, "_blank", "noopener, noreferrer");
};

export const formatDateInWords = (date: string) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const current_date = new Date(date);
  const month_value = current_date.getMonth();
  const day_value = current_date.getDate();
  const year = current_date.getUTCFullYear();
  let hour: any = current_date.getHours();
  let mins: any = current_date.getMinutes();

  if (hour < 10) hour = `0${hour}`;
  if (mins < 10) mins = `0${mins}`;

  return date
    ? `${day_value} ${months[month_value]}, ${year} | ${hour}:${mins}`
    : "";
};

export const formatNumber = (n: number, decimals: number) => {
  return (
    n &&
    Number(n)
      .toFixed(decimals || 0)
      .replace(/./g, function (c, i, a) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
      })
  );
};

export const firstLetter = (letter: string) => {
  var str = letter;
  var res = str?.substring(0, 1);
  return res;
};

export const getInitials = (letter: string) => {
  if (letter.includes("--")) return "- -";
  var names = letter.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const hexCodeGenerate = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);

  return "#" + randomColor;
};

export const dateToFromNow = (date: Date) => {
  // get from-now for this date
  var fromNow = moment(date).fromNow();

  // ensure the date is displayed with today and yesterday
  return moment(date).calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: "[Last] dddd",
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    // when the date is further away, use from-now functionality
    sameElse: function () {
      return "[" + fromNow + "]";
    },
  });
};

// Form Validations
export const emailFormValidation = (required: boolean) => {
  return {
    required: required ? "Email address is required" : false,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Invalid email address",
    },
  };
};
export const nameFormValidation = (required: boolean) => {
  return {
    required: required ? "This field is required" : false,
    pattern: {
      value: /^[A-Za-z]+$/,
      message: "Invalid username",
    },
    minLength: {
      value: 2,
      message: "Must be more than 1 character",
    },
  };
};
export const textFormValidation = (required: boolean, minLength?: number) => {
  return {
    required: required ? "This field is required" : false,
    minLength: minLength
      ? {
          value: minLength + 1,
          message: "",
        }
      : {
          value: 2,
          message: "",
        },
  };
};
export const numberFormValidation = (
  required: boolean,
  minLength: number,
  maxLength: number,
  min?: number,
  max?: number
) => {
  return {
    required: required ? "This field is required" : false,
    minLength: {
      value: minLength,
      message: `Minimum characters is ${minLength}`,
    },
    maxLength: {
      value: maxLength,
      message: `Maximum characters is ${maxLength}`,
    },
    min: {
      value: min || 0,
      message: `Minimum value is ${min || 0}`,
    },
    max: {
      value: max || 10000000000000000000,
      message: `Maximum value is ${max || 10000000000000000000}`,
    },
  };
};
export const passwordFormValidation = (required: boolean) => {
  return {
    required: required ? "This field is required" : false,
    minLength: {
      value: 8,
      message: "Must use at least 8 characters",
    },
  };
};
export const basicFormValidation = (required: boolean) => {
  return {
    required: required ? "This field is required" : false,
  };
};

export const urlValidation = (userInput: string) => {
  var res = userInput?.match(
    // eslint-disable-next-line no-useless-escape
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  if (res == null) {
    return true;
  }
  return false;
};

export const convertStringToArray = (input: string) => {
  // Split the string by semicolon delimiter
  if (typeof input === "string") {
    // If input is a string, split it by semicolon delimiter and convert to numbers
    const values = input?.includes(";") ? input.split(";") : input.split(",");

    return values.map((value) => {
      return value.trim();
    });
  }
};

export const toCapitalize = (str: string) => {
  let result = str.charAt(0).toUpperCase() + str.slice(1);

  return result;
};

export const removeWord = (originalString: string, wordToRemove: string) => {
  const words = originalString?.split(", ");
  const filteredWords = words?.filter((word) => word !== wordToRemove);
  return filteredWords.join(", ").trim();
};

export const extractUniqueWord = (
  stringArr: string[],
  string: string
): string => {
  const words2 = string?.split(", ");

  const uniqueToSecond = words2?.filter((word) => !stringArr?.includes(word));

  if (!uniqueToSecond) return "";

  return uniqueToSecond.join(", ").trim();
};

export const getBrandToneClassNames = (index: number, tagName: string) => {
  const isNewTag = !brandTags.find((tag) => tag.tag === tagName);
  // console.log("isNewTag:", isNewTag);
  return {
    tag_style_1: isNewTag
      ? brandTagStyleClassNames[index] === "tag_style_1"
      : tag_style_one.includes(tagName),
    tag_style_2: isNewTag
      ? brandTagStyleClassNames[index] === "tag_style_2"
      : tag_style_two.includes(tagName),
    tag_style_3: isNewTag
      ? brandTagStyleClassNames[index] === "tag_style_3"
      : tag_style_three.includes(tagName),
    tag_style_4: isNewTag
      ? brandTagStyleClassNames[index] === "tag_style_4"
      : tag_style_four.includes(tagName),
  };
};

export const validateFileSizeAndType = (
  files: FileList,
  maxSizeInBytes: number
) => {
  const allowedMimeTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
  ];
  const validFiles = [];

  for (let i = 0; i < files?.length; i++) {
    const file = files![i];
    const fileType = file.type;

    if (allowedMimeTypes.includes(fileType) && file.size <= maxSizeInBytes) {
      validFiles.push(file);
    } else {
      if (!allowedMimeTypes.includes(fileType)) {
        alert(`Invalid file type: ${file.name}`);
      } else {
        alert(`File size exceeds maximum limit: ${file.name}`);
      }
    }
  }
};

export const convertFilesArrayToBuffer = async (
  files: Array<File>
): Promise<Array<{ type: string; buffer: Uint8Array }> | null> => {
  if (files) {
    const fileData: { type: string; buffer: Uint8Array }[] = [];

    const promises = Array.from(files).map(
      (file) =>
        new Promise<{ type: string; buffer: Uint8Array }>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () =>
            resolve({
              type: file.type,
              buffer: new Uint8Array(reader.result as ArrayBuffer),
            });
          reader.onerror = reject;
          reader.readAsArrayBuffer(file);
        })
    );

    try {
      const results = await Promise.all(promises);
      fileData.push(...results);

      console.log(fileData);
      return fileData;
    } catch (error) {
      console.error("Error reading files:", error);
    }
  }

  return null;
};

export const convertFileToBase64 = async (
  file: File | undefined
): Promise<string> => {
  if (!file) throw new Error("File not found");
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject(new Error("Error reading file"));
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export const objectToFormData = (obj: Record<string, any>): FormData => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      // value.forEach((item) => formData.append(`${key}[]`, item));
      formData.append(key, value?.toString());
    } else if (value instanceof File) {
      formData.append(key, value);
    } else if (value instanceof ArrayBuffer) {
      const blob = new Blob([value]);
      formData.append(key, blob, key);
    } else {
      formData.append(key, value?.toString());
    }
  }

  return formData;
};

export const extractEmails = (combinedArray: string[]): string[] => {
  return combinedArray.map((combinedString) => {
    const parts = combinedString.split(";");
    return parts[0].trim(); // Extract and trim the email address
  });
};

export const insertDivAsFirstChild = (
  htmlString: string,
  divElement: string
): string => {
  // Create a DOMParser to parse the HTML string
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Select the <body> element
  const body = doc.querySelector("body");

  // Create a new element from the div string
  const newDiv = doc.createElement("div");
  newDiv.innerHTML = divElement;

  // Insert the new div as the first child of the body
  body?.insertBefore(newDiv, body.firstChild);

  // Serialize the modified DOM back to a string
  return new XMLSerializer().serializeToString(doc);
};

export const convertEscapedStringToHTML = (escapedString: string) => {
  // Decode HTML entities like &lt;, &gt;, and &amp; to their original characters
  const htmlDecoded = escapedString
    ?.replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&")
    .replace(/\r\n/g, "") // Replace \r\n with \n for better formatting
    .replace(/\t/g, "") // Remove tabs if any
    .replace(/&quot;/g, '"');
  // Return the pure HTML string
  return htmlDecoded;
};

export function startCountdown(
  days: number,
  onUpdate: (d: number, h: number, m: number, s: number) => void,
  onFinish?: () => void
): number {
  let totalSeconds = days * 24 * 60 * 60;

  // Immediately invoke callback for the initial state
  onUpdate(
    getDays(totalSeconds),
    getHours(totalSeconds),
    getMinutes(totalSeconds),
    getSeconds(totalSeconds)
  );

  const intervalId = window.setInterval(() => {
    totalSeconds -= 1;

    // If we've reached below zero, end the countdown
    if (totalSeconds < 0) {
      clearInterval(intervalId);
      onFinish?.();
      return;
    }

    // Invoke callback with new time
    onUpdate(
      getDays(totalSeconds),
      getHours(totalSeconds),
      getMinutes(totalSeconds),
      getSeconds(totalSeconds)
    );
  }, 1000);

  return intervalId;
}

// Helper functions to break down `totalSeconds`
function getDays(totalSeconds: number) {
  return Math.floor(totalSeconds / (24 * 60 * 60));
}

function getHours(totalSeconds: number) {
  return Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
}

function getMinutes(totalSeconds: number) {
  return Math.floor((totalSeconds % (60 * 60)) / 60);
}

function getSeconds(totalSeconds: number) {
  return Math.floor(totalSeconds % 60);
}

export const extractBodyContent = (
  html: string
): {
  bodyContent: string;
  remainder: string;
} => {
  // This regex captures the first <body ...> tag through its corresponding </body>,
  // including any content (newline or otherwise) in between. The 'i' flag makes it case-insensitive.
  const bodyRegex = /(<body[^>]*>[\s\S]*?<\/body>)/i;

  const match = html.match(bodyRegex);
  if (!match) {
    // No <body> found; return the original HTML as remainder and an empty string for bodyContent
    return {
      bodyContent: "",
      remainder: html,
    };
  }

  // match[0] is the entire <body>...</body> match
  const bodyContent = match[0];

  // Remove the matched substring from the original HTML to get the remainder
  const remainder = html.replace(bodyRegex, "");

  return {
    bodyContent,
    remainder,
  };
};
