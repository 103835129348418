import Button from "../../../components/button";
import ComingSoon from "../../../components/coming-soon";
import { APP_USER } from "../../../utils/constants";
import { useLocalStorage } from "../../../utils/functions";

type Props = {
  brandData: any;
};

const BrandSummary = ({ brandData }: Props) => {
  console.log("brand-summary:", brandData?.store_ai_summary);
  const storeName = useLocalStorage.get(APP_USER)?.company_name;
  return (
    <ComingSoon name="Brand Summary" daysToCountdown={9.5} />
    // <div className="brand_summary">
    //   <div className="heading">
    //     <p className="title">Brand Summary</p>
    //     <p className="sub_header">
    //       This summary reflects the AI's current knowledge based on your
    //       training data. To incorporate new information, click "Update"
    //     </p>
    //   </div>
    //   <div className="summary_container">
    //     <p>{storeName}</p>
    //     <div
    //       className="summary_content"
    //       dangerouslySetInnerHTML={{ __html: brandData?.store_ai_summary }}
    //     >
    //       {/* {brandData?.store_ai_summary} */}
    //     </div>
    //   </div>
    //   <div className="update_summary">
    //     <p>Any more Information you will like to give?</p>
    //     <div className="input">
    //       <input placeholder="Enter a description..." />
    //       <Button text="update" />
    //     </div>
    //   </div>
    //   <div className="save_btn">
    //     <Button text="save" />
    //   </div>
    // </div>
  );
};

export default BrandSummary;
