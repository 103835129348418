import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { FaRegEnvelope } from "react-icons/fa";
import { fetchProfileAction, updateProfileAction } from "../redux/auth/user-slice";
import FormFieldName from "./form-field-name";
import FormInput from "./form-input";
import { emailFormValidation, getUserDetails, numberFormValidation, textFormValidation } from "../utils/functions";
import FileAndColorPicker from "./form-picker";
import { useAppDispatch } from "../redux";
import CustomDropdown from "./custom-dropdown";
import Button from "./button";
import OutlineButton from "./outline-button";
import { createValidationRules } from "../utils/validations";

type FormInputs = {
    store_name: string;
    store_email: string;
    store_phone: string;
    fb_link: string;
    x_link: string;
    inst_link: string;
    yout_link: string;
    oth_link: string;
    utm_code: string;
    privacy: string;
    term_service: string;
};

type BrandLogoState = {
    file: File | null;
    url: string | undefined;
};


const StoreData = () => {
    const dispatch = useAppDispatch();
    const { user } = useSelector((state: any) => state.userSlice);
    const typographyOptions = ["Arial", "Gothic", "Times New Roman", "Verdana"];
    const [selectedFont, setSelectedFont] = useState(typographyOptions[0]);
    const [brandLogo, setBrandLogo] = useState<BrandLogoState>({
        file: null,
        url: undefined
    }); const [primaryColor, setPrimaryColor] = useState("#000000");
    const [secondaryColor, setSecondaryColor] = useState("#000000");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm<FormInputs>({
        mode: "onChange",
    });

    useEffect(() => {
        const fetchAndInitializeData = async () => {
            try {
                await dispatch(fetchProfileAction());
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        fetchAndInitializeData();
    }, []);

    useEffect(() => {
        if (user) {
            reset({
                store_name: user.store_name || '',
                store_email: user.store_email || '',
                store_phone: user.store_phone || '',
                fb_link: user.fb_link || '',
                inst_link: user.inst_link || '',
                x_link: user.x_link || '',
                yout_link: user.yout_link || '',
                oth_link: user.oth_link || '',
                utm_code: user.utm_code || '',
                privacy: user.privacy || '',
                term_service: user.term_service || '',
            });

            setBrandLogo({
                file: null,
                url: user.brand_logo_url || undefined
            });

            setPrimaryColor(user.pry_color || "#000000");
            setSecondaryColor(user.second_color || "#000000");
            console.log('Setting font from user data:', user.typography);

            if (user.typography && selectedFont !== user.typography) {
                setSelectedFont(user.typography);
            }
            else if (!user.typography && selectedFont === null) {
                setSelectedFont(typographyOptions[selectedFont]);
            }
        }
    }, [user, reset]);
    useEffect(() => {
        console.log('Selected font changed to:', selectedFont);
    }, [selectedFont]);
    const privacyValue = watch('privacy');
    const termServiceValue = watch('term_service');

    useEffect(() => {
        const hasRequiredFields =
            (brandLogo.file !== null || brandLogo.url !== undefined) &&
            primaryColor !== "#000000" &&
            privacyValue?.trim() !== '' &&
            termServiceValue?.trim() !== '' &&
            selectedFont !== '';

        setIsFormValid(hasRequiredFields);
    }, [brandLogo, primaryColor, privacyValue, termServiceValue, selectedFont]);

    const handleFileChange = (file: File | null) => {
        setBrandLogo({
            file,
            url: file ? URL.createObjectURL(file) : undefined
        });
    };

    const handlePrimaryColorChange = (color: string) => {
        setPrimaryColor(color);
    };

    const handleSecondaryColorChange = (color: string) => {
        setSecondaryColor(color);
    };


    const handleFontSelect = (font: string) => {
        setSelectedFont(font);
    };

    const submitFormOnSubmit = async (formData: FormInputs) => {
        if (!isFormValid) return;
        setIsSubmitting(true);

        const companySlug = getUserDetails()?.company_slug;

        console.log("Collected Data:", { slug: companySlug, ...formData });


        const submitData = new FormData();

        // submitData.append('user_id', user?.user_id);
        submitData.append('slug', companySlug);

        Object.entries(formData).forEach(([key, value]) => {
            if (value !== undefined && value !== null && value !== '') {
                submitData.append(key, value);
            }
        });

        if (brandLogo.file) {
            submitData.append('brand_logo', brandLogo.file);
        }
        submitData.append('pry_color', primaryColor);
        submitData.append('second_color', secondaryColor);
        submitData.append('typography', selectedFont);

        console.log("submit:")

        try {
            const result = await dispatch(updateProfileAction(submitData));
            if (result?.success) {
                // window.location.reload();
                console.log("Result", result);
            }
        } finally {
            setIsSubmitting(false);
        }
        console.log("Result", submitData);

    };


    return (
        <form
            onSubmit={handleSubmit(submitFormOnSubmit)}
        >
            <div className="store_data_section">
                <div className="store_data_header">
                    <div>
                        <p className="store_header_text">Store Data</p>
                        <p className="">Update your profile information</p>
                    </div>
                    <div>
                        <div className="store-data_btn-container">
                            <div className="action store_data_header">
                                <OutlineButton
                                    text="Cancel"
                                    type="button"
                                    loading={isSubmitting}
                                    disabled={isSubmitting}
                                />
                                <Button
                                    text="Save"
                                    type="submit"
                                    loading={isSubmitting}
                                    disabled={!isFormValid || isSubmitting}
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <hr className="divider" />

                <div className="store_data_container">
                    <div className="data_container">
                        <div>
                            <FormFieldName name="Store handler name" />

                            <FormInput
                                label="Store handler name"
                                type="text"
                                placeholder="Name"
                                readOnly={isSubmitting}
                                errorMessage={errors?.store_name?.message}
                                inputRef={{
                                    ...register("store_name", createValidationRules('text', false, 2, 50)),
                                }}
                            />
                        </div>
                        <div>
                            <FormFieldName name="Store email address" />
                            <FormInput
                                type="text"
                                placeholder="Email address"
                                readOnly={isSubmitting}
                                errorMessage={errors?.store_email?.message}
                                inputRef={{
                                    ...register("store_email", createValidationRules('email', false)),
                                }}
                                leftIcon={<FaRegEnvelope />}
                            />

                        </div>

                        <div>
                            <FormFieldName name="Phone number" />
                            <FormInput
                                type="text"
                                placeholder="Phone number"
                                readOnly={isSubmitting}
                                errorMessage={errors?.store_phone?.message}
                                inputRef={{
                                    ...register("store_phone", createValidationRules('phone', false)),
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <p className="store_header_text">Brand Assets</p>
                    <p className="">Specific brand assets for template customization</p>
                </div>
                <hr className="divider" />



                <div className="store_data_container">

                    <div>
                        <FormFieldName name="Upload brand logo" additionalText="(250 x 150px JPEG, PNG)"
                            isRequired={true} />

                        <FileAndColorPicker
                            fileLabel="Upload Profile Image"
                            primaryColorLabel="Profile Primary Color"
                            secondaryColorLabel="Profile Secondary Color"
                            onFileChange={handleFileChange}
                            onPrimaryColorChange={handlePrimaryColorChange}
                            onSecondaryColorChange={handleSecondaryColorChange}
                            defaultPrimaryColor={primaryColor}
                            defaultSecondaryColor={secondaryColor}
                            existingImageUrl={brandLogo.url}
                        />
                    </div>
                    <div>
                        <div>
                            <FormFieldName name="Privacy Policy" isRequired={true} />
                            <FormInput
                                type="text"
                                placeholder="Insert Url"
                                readOnly={isSubmitting}
                                errorMessage={errors?.privacy?.message}
                                inputRef={{
                                    ...register("privacy", createValidationRules('url', true)),
                                }}
                            />

                        </div>
                        <div>
                            <FormFieldName name="Terms of service" isRequired={true} />
                            <FormInput
                                type="text"
                                placeholder="Insert Url"
                                readOnly={isSubmitting}
                                errorMessage={errors?.term_service?.message}
                                inputRef={{
                                    ...register("term_service", createValidationRules('url', true)),
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <FormFieldName name="Select Typography" isRequired={true} />
                        <CustomDropdown
                            options={typographyOptions}
                            defaultSelected={selectedFont}
                            onSelect={handleFontSelect}
                        />
                    </div>
                    <div className="url_container">
                        <div>
                            <FormFieldName name="Facebook account link" />
                            <FormInput
                                type="text"
                                placeholder="Insert Url"
                                readOnly={isSubmitting}
                                errorMessage={errors?.fb_link?.message}
                                inputRef={{
                                    ...register("fb_link", createValidationRules('socialMedia', false, undefined, undefined, { platform: "facebook" }))
                                }}
                            />
                        </div>
                        <div>
                            <FormFieldName name="Instagram account link" />
                            <FormInput
                                type="text"
                                placeholder="Insert Url"
                                readOnly={isSubmitting}
                                errorMessage={errors?.inst_link?.message}
                                inputRef={{
                                    ...register("inst_link", createValidationRules('socialMedia', false, undefined, undefined, { platform: "instagram" }))
                                }}
                            />
                        </div>
                        <div>
                            <FormFieldName name="X account link" />
                            <FormInput
                                type="text"
                                placeholder="Insert Url"
                                readOnly={isSubmitting}
                                errorMessage={errors?.x_link?.message}
                                inputRef={{
                                    ...register("x_link", createValidationRules('socialMedia', false, undefined, undefined, { platform: "twitter" })),
                                }}
                            />
                        </div>
                        <div>
                            <FormFieldName name="YouTube account link" />
                            <FormInput
                                type="text"
                                placeholder="Insert Url"
                                readOnly={isSubmitting}
                                errorMessage={errors?.yout_link?.message}
                                inputRef={{
                                    ...register("yout_link", createValidationRules('socialMedia', false, undefined, undefined, { platform: "youtube" })),
                                }}
                            />
                        </div>
                        <div>
                            <FormFieldName name="Other link" />
                            <FormInput
                                type="text"
                                placeholder="Insert Url"
                                readOnly={isSubmitting}
                                errorMessage={errors?.oth_link?.message}
                                inputRef={{
                                    ...register("oth_link", createValidationRules('url', false)),
                                }}
                            />
                        </div>
                    </div>

                    <div>
                        <div className="other_container">
                            <FormFieldName name="UTM Code" />

                            <p>(UTM codes provide information about the source, medium, and journey name of website visitors.)</p>
                        </div>
                        <FormInput
                            type="text"
                            placeholder="Insert Code"
                            readOnly={isSubmitting}
                            errorMessage={errors?.utm_code?.message}
                            inputRef={{
                                ...register("utm_code", textFormValidation(false)),
                            }}
                        />
                    </div>
                </div>
                <div className="store-data_btn-container">
                    <div className="action store_data_header">
                        <OutlineButton
                            text="Cancel"
                            type="button"
                            loading={isSubmitting}
                            disabled={isSubmitting}
                        />
                        <Button
                            text="Save"
                            type="submit"
                            loading={isSubmitting}
                            disabled={!isFormValid || isSubmitting}
                        />
                    </div>
                </div>

            </div>
        </form>
    );
};

export default StoreData;