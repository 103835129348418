import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import moment from "moment";
import PageTitle from "../../components/page-title";
import Topbar from "../../components/topbar";
import DatePickerComponent from "../../components/datepicker";
import FormSelect from "../../components/form-select";
import Loader from "../../components/loader";

import {
  getDailyVideoAction,
  getNoDailyVideoAction,
  getTopInsightsAction,
} from "../../redux/dashboard/home-slice";


import DashboardChart from "../../components/dashboard_chart";
import { getUniqueOpenRatesAction } from "../../redux/dashboard/campaigns-slice";

type SelectOption = {
  label: string;
  value: any;
};



const chartPlotOptions = [
  { label: "Unique Open Rates", value: "uniqueOpenRates" }
];

type DashboardPageComponent = () => JSX.Element;


const DashboardPage: DashboardPageComponent = () => {
  const dispatch = useAppDispatch();


  const {
    dailyVideoSrc,
    isFetchingInsights,
    insightsData,
    isFetchingUniqueOpenRates,
    openRateAnalytics,

  } = useSelector((state: any) => ({
    ...state.homeSlice,
    ...state.campaignsSlice
  }));

  const [error, setError] = useState<string | null>(null);
  console.log('Current state:', {
    isFetchingUniqueOpenRates,
    openRateAnalytics,
    error
  });


  const [queryDate, setQueryDate] = useState<any>(moment().subtract(1, "days"));
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    moment().subtract(4, 'months').toDate(),
    new Date()
  ]);
  const [startDate, endDate] = dateRange;
  const [topInsightsData, setTopInsightsData] = useState<any>([]);


  const fetchAnalyticsData = useCallback(async () => {
    if (!startDate || !endDate) {
      console.log('Missing date range, skipping fetch');
      return;
    }

    console.log('Fetching analytics data for date range:', {
      start: moment(startDate).format("YYYY-MM-DD"),
      end: moment(endDate).format("YYYY-MM-DD")
    });

    try {
      setError(null);
      const res = await dispatch(getUniqueOpenRatesAction(
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD")
      ));

      console.log('Fetch response:', res);

      if (!res?.success && !res?.data) {
        console.error('Fetch failed:', res);
        setError(res?.error || "Failed to fetch analytics data");
      }
    } catch (error: any) {
      console.error('Error in fetchAnalyticsData:', error);
      setError(error.message || "An unexpected error occurred");
    }
  }, [startDate, endDate, dispatch]);

  useEffect(() => {
    console.log('Date range changed, triggering fetch');
    fetchAnalyticsData();
  }, [fetchAnalyticsData])

  useEffect(() => {
    if (insightsData) {
      setTopInsightsData(JSON.parse(insightsData));
    } else {
      setTopInsightsData([]);
    }
  }, [insightsData]);

  const getQueryDateData = () => {
    dispatch(getDailyVideoAction(queryDate.format("YYYY-MM-DD"))).then(
      (res) => {
        if (res?.status !== 200) {
          dispatch(getNoDailyVideoAction());
        }
      }
    );

    dispatch(getTopInsightsAction(queryDate.format("YYYY-MM-DD")));
  };

  useEffect(() => {
    getQueryDateData();
  }, [queryDate]);

  const datePickerDisplayValue = (date: Date) => {
    return moment(date)?.format("MMM DD, yyyy");
  };

  if (isFetchingInsights) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <PageTitle title="Dashboard" />
      <Topbar
        title="Dashboard"
        onSearch={() => { }}
        activeBtnFilter={""}
        hideLayoutStyle={true}
      />

      <div className="page_container dash_home_page_container">
        <div className="top_actions">
          <DatePickerComponent
            value={datePickerDisplayValue(queryDate)}
            onChange={(date: any) => setQueryDate(moment(date))}
            range={false}
            maxDate={new Date()}
          />
        </div>

        <div className="grid_layout">
          <div className="video_update">
            <video
              className="video_player"
              src={dailyVideoSrc}
              controls
            ></video>
            <p className="title">
              Daily Video Update for {queryDate.format("MMMM Do, YYYY")}
            </p>
            <p className="text">See what's going on on your Konvas Account</p>
          </div>

          <div className="insights">
            <div className="insight">
              <p className="title">
                Top Insights for {queryDate.format("MMMM Do, YYYY")}
              </p>

              <div className="list">
                {topInsightsData?.length > 0 ? (
                  topInsightsData?.map((item: string, i: number) => (
                    <p key={i}>{item}</p>
                  ))
                ) : (
                  <p>
                    You do not have Top Insights for{" "}
                    {queryDate.format("MMMM Do, YYYY")}. Select a different
                    date or check tomorrow.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="chart_graph_container">
          <div className="top">
            <p>Analytics</p>
            <div className="chart_filter">
              <DatePickerComponent
                startDate={startDate}
                endDate={endDate}
                onChange={(update: [Date | null, Date | null]) => {
                  console.log('Date range updated:', update);

                  setDateRange(update);
                  setError(null);
                }}
                range={true}
                maxDate={new Date()}
                placeholderText="Select date range"
              />

            </div>

          </div>

          <DashboardChart
            analyticsData={{
              status: true,
              data: openRateAnalytics
            }}
            isLoading={isFetchingUniqueOpenRates}
            error={error}
            onRetry={fetchAnalyticsData} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardPage;