import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import { Link } from "react-router-dom";

import PageTitle from "../../components/page-title";
import SegmentsTopbar from "./segments-topbar";
import Loader from "../../components/loader";
import NoData from "../../components/no-data";
import FormSelect from "../../components/form-select";
import NewCustomSegmentModal from "../../components/modal/new-custom-segment-modal";

import {
  getSegmentsAction,
  postAddSegmentToDbAction,
} from "../../redux/dashboard/segments-slice";

import { hexCodeGenerate } from "../../utils/functions";
import {
  APP_LIST_LAYOUT_GRID,
  APP_LIST_LAYOUT_LIST,
} from "../../utils/constants";
import SegmentInfoPage from "./segment-info";
import ComingSoon from "../../components/coming-soon";

const listOrderOptions = [
  { label: "Most Recent", value: "desc" },
  { label: "Oldest", value: "asc" },
];

const SegmentsPage = () => {
  const dispatch = useAppDispatch();

  const { listLayout } = useSelector((state: any) => state.componentsSlice);
  const { isFetchingSegments, segments } = useSelector(
    (state: any) => state.segmentsSlice
  );

  const [param, setParam] = useState("ai");
  const [listOrder, setListOrder] = useState(listOrderOptions[0]);
  const [segmentId, setSegmentId] = useState("");
  const [showSegmentDetails, setShowSegmentDetails] = useState(false);
  const [isNewCustomSegmentModalOpen, setIsNewCustomSegmentModalOpen] =
    useState(false);

  const getSegments = (value: string) => {
    setParam(value);

    dispatch(getSegmentsAction({ type: value }));
  };

  useEffect(() => {
    getSegments(param);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postAddSegmentToDb = (payload: any) => {
    dispatch(postAddSegmentToDbAction(payload)).then((res: any) => {
      if (res?.success === true) {
        getSegments(param);
        setIsNewCustomSegmentModalOpen(false);
      }
    });
  };

  const handleSegmentDetails = (id: string) => {
    setSegmentId(id);
    setShowSegmentDetails(true);
  };

  const segmentsData =
    listOrder?.value === listOrderOptions[0].value
      ? segments || []
      : segments?.slice()?.reverse() || [];

  const segmentsAvailable = segmentsData?.length > 0;

  console.log("segmentsData:", segmentsData);

  return (
    <React.Fragment>
      <PageTitle title="Segments" />

      <ComingSoon name="Segments" daysToCountdown={9.5} />

      {/* <SegmentsTopbar
        generateCustom={() => setIsNewCustomSegmentModalOpen(true)}
        onFilter={getSegments}
      />

      <div className="page_container segments_page_container">
        {isFetchingSegments && <Loader />}

        {!isFetchingSegments && segmentsAvailable ? (
          <>
            {showSegmentDetails && segmentId ? (
              <SegmentInfoPage
                id={segmentId}
                setShowSegmentDetails={setShowSegmentDetails}
              />
            ) : (
              <>
                <div className="top_filters">
                  <FormSelect
                    name="filterSelect"
                    defaultValue={listOrder}
                    options={listOrderOptions}
                    onChange={(value: any) => setListOrder(value)}
                  />
                </div>

                {listLayout === APP_LIST_LAYOUT_LIST && (
                  <div className="vertical_scroll">
                    <div className="layout list_layout">
                      {segmentsData?.map((item: any, i: any) => (
                        <div
                          key={i}
                          className="item"
                          onClick={() => handleSegmentDetails(item.id)}
                        >
                          <div className="sn_and_title">
                            <p
                              className="sn"
                              style={{ color: `${hexCodeGenerate()}` }}
                            >
                              {i + 1}
                            </p>

                            <p className="title">{item.segment_name}</p>
                          </div>

                          <p
                            className="info"
                            dangerouslySetInnerHTML={{
                              __html: item.segment_description,
                            }}
                          ></p>

                          {item?.customers && (
                            <p className="customers">
                              {Number(
                                JSON.parse(item?.customers || [])?.length
                              ).toLocaleString("en-US")}{" "}
                              customers
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {listLayout === APP_LIST_LAYOUT_GRID && (
                  <div className="layout grid_layout">
                    {segmentsData?.map((item: any, i: any) => (
                      <div key={i} className="item">
                        <div className="top">
                          <p
                            className="sn"
                            style={{ color: `${hexCodeGenerate()}` }}
                          >
                            {i + 1}
                          </p>
                        </div>
                        <Link to={`/segments/${item.id}`} className="title">
                          {item.segment_name}
                        </Link>

                        <p
                          className="info"
                          dangerouslySetInnerHTML={{
                            __html: item.segment_description,
                          }}
                        ></p>

                        <div className="footer">
                          {item?.customers && (
                            <p className="customers">
                              {Number(
                                JSON.parse(item?.customers || [])?.length
                              )?.toLocaleString("en-US")}{" "}
                              customers
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </>
        ) : !isFetchingSegments && segmentsData?.length === 0 ? (
          <NoData text={`No ${param} generated segments`} />
        ) : (
          <></>
        )}
      </div> */}

      <NewCustomSegmentModal
        isOpen={isNewCustomSegmentModalOpen}
        close={() => setIsNewCustomSegmentModalOpen(false)}
        onSubmit={(payload) => postAddSegmentToDb(payload)}
      />
    </React.Fragment>
  );
};

export default SegmentsPage;
