type ValidationField = 'text' | 'email' | 'phone' | 'url' | 'socialMedia';

interface SocialMediaPlatform {
  platform: 'facebook' | 'instagram' | 'twitter' | 'youtube';
}

export const createValidationRules = (
  field: ValidationField,
  isRequired: boolean = false,
  minLength?: number,
  maxLength?: number,
  socialMedia?: SocialMediaPlatform
) => {
  const rules: any = {};

  if (isRequired) {
    rules.required = "This field is required";
  }

  if (minLength) {
    rules.minLength = {
      value: minLength,
      message: `Minimum length is ${minLength} characters`
    };
  }

  if (maxLength) {
    rules.maxLength = {
      value: maxLength,
      message: `Maximum length is ${maxLength} characters`
    };
  }

  // Field-specific validation patterns
  const patterns = {
    email: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Invalid email address"
    },
    phone: {
      value: /^[0-9+\-() ]+$/,
      message: "Invalid phone number"
    },
    url: {
      value: /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
      message: "Invalid URL. Must start with http:// or https://"
    }
  };

  const socialMediaPatterns = {
    facebook: /^https?:\/\/(www\.)?(facebook|fb)\.com/,
    instagram: /^https?:\/\/(www\.)?instagram\.com/,
    twitter: /^https?:\/\/(www\.)?(?:twitter\.com|x\.com)/,
    youtube: /^https?:\/\/(www\.)?youtube\.com/
  };

  // Add field-specific validation
  switch (field) {
    case 'email':
      rules.pattern = patterns.email;
      break;
    case 'phone':
      rules.pattern = patterns.phone;
      break;
    case 'url':
      rules.pattern = patterns.url;
      // Additional URL validation
      rules.validate = {
        validProtocol: (value: string) => {
          if (!value && !isRequired) return true;
          try {
            const url = new URL(value);
            return (url.protocol === 'http:' || url.protocol === 'https:') || 
              "URL must start with http:// or https://";
          } catch {
            return "Please enter a valid URL";
          }
        }
      };
      break;
    case 'socialMedia':
      if (socialMedia?.platform) {
        rules.pattern = {
          value: socialMediaPatterns[socialMedia.platform],
          message: `Please enter a valid ${socialMedia.platform} URL`
        };
        rules.validate = {
          validProtocol: (value: string) => {
            if (!value && !isRequired) return true;
            try {
              const url = new URL(value);
              return (url.protocol === 'http:' || url.protocol === 'https:') || 
                "URL must start with http:// or https://";
            } catch {
              return "Please enter a valid URL";
            }
          }
        };
      }
      break;
  }

  return rules;
};