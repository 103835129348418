import React, { useEffect, useRef, useState } from "react";

type Props = {
  fileLabel?: string;
  primaryColorLabel?: string;
  secondaryColorLabel?: string;
  onFileChange: (file: File | null) => void;
  onPrimaryColorChange: (color: string) => void;
  onSecondaryColorChange: (color: string) => void;
  defaultPrimaryColor?: string;
  defaultSecondaryColor?: string;
  existingImageUrl?: string
};

const lightenColor = (color: string, intensity: number): string => {
  const num = parseInt(color.replace("#", ""), 16);
  const r = (num >> 16) & 0xff;
  const g = (num >> 8) & 0xff;
  const b = num & 0xff;

  const mix = (channel: number) => Math.min(Math.round(channel + intensity * (255 - channel)), 255);

  return `rgb(${mix(r)}, ${mix(g)}, ${mix(b)})`;
};


const FileAndColorPicker = ({
  fileLabel = "Choose file",
  primaryColorLabel = "Select Primary Color",
  secondaryColorLabel = "Select Secondary Color",
  onFileChange,
  onPrimaryColorChange,
  onSecondaryColorChange,
  defaultPrimaryColor = "#000000",
  defaultSecondaryColor = "#000000",
  existingImageUrl,
}: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const [primaryColor, setPrimaryColor] = useState<string>(defaultPrimaryColor);
  const [secondaryColor, setSecondaryColor] = useState<string>(defaultSecondaryColor);

  useEffect(() => {
    setPrimaryColor(defaultPrimaryColor);
    setSecondaryColor(defaultSecondaryColor);
  }, [defaultPrimaryColor, defaultSecondaryColor]);


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      onFileChange(selectedFile);

    }
    e.target.value = '';
  };

  const getDisplayFileName = () => {
    if (file) {
      return file.name;
    }
    if (existingImageUrl) {
      try {
        const url = new URL(existingImageUrl);
        const pathParts = url.pathname.split('/');
        const fileName = pathParts[pathParts.length - 1];
        return decodeURIComponent(fileName || 'Current logo');
      } catch {
        return 'Current logo';
      }
    }
    return '';
  };


  const handlePrimaryColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const color = e.target.value;
    setPrimaryColor(color);
    onPrimaryColorChange(color);
  };

  const handleSecondaryColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const color = e.target.value;
    setSecondaryColor(color);
    onSecondaryColorChange(color);
  };


  return (
    <div className="file-and-color-picker">
      <div className="form-group">
        <div className="picker-padding">
          <div className="picker_container">
            <label htmlFor="file-upload" className="file-label">
              <input
                type="file"
                id="file-upload"
                onChange={handleFileChange}
                className="file-input"
              />
              {fileLabel}
            </label>
            {(file || existingImageUrl) && (
              <span className="selected-file-name">
                {getDisplayFileName()}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="color-picker-container">
        <div>
          <label className="color-picker_label">{primaryColorLabel}<span style={{ color: "red" }}> *</span></label>

          <div className="picker_container">
            <div className="color-picker">
              <button
                type="button"
                onClick={() =>
                  document.getElementById("primary-color-picker")?.click()
                }
              >
                Pick Color
              </button>
              <input
                type="color"
                id="primary-color-picker"
                value={primaryColor}
                onChange={handlePrimaryColorChange}
                style={{
                  position: "absolute",
                  opacity: 0,
                  pointerEvents: "none",
                }}
              />
              <p
                style={{
                  color: primaryColor,
                  backgroundColor: lightenColor(primaryColor, 0.7),
                  border: `1px solid ${primaryColor}`,
                  padding: "4px",
                  borderRadius: "4px",
                  fontWeight: "normal",
                  display: "inline-block",
                }}
              >
                {primaryColor}
              </p>
            </div>
          </div>
        </div>

        <div>
          <label className="color-picker_label">{secondaryColorLabel}</label>

          <div className="picker_container">
            <div className="color-picker">
              <button
                type="button"
                onClick={() =>
                  document.getElementById("secondary-color-picker")?.click()
                }
              >
                Pick Color
              </button>
              <input
                type="color"
                id="secondary-color-picker"
                value={secondaryColor}
                onChange={handleSecondaryColorChange}
                style={{
                  position: "absolute",
                  opacity: 0,
                  pointerEvents: "none",
                }}
              />
              <span
                style={{
                  color: secondaryColor,
                  backgroundColor: lightenColor(secondaryColor, 0.7),
                  border: `1px solid ${secondaryColor}`,
                  padding: "4px",
                  borderRadius: "4px",
                  fontWeight: "normal",
                  display: "inline-block",
                }}
              >
                {secondaryColor}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileAndColorPicker;
