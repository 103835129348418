import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../redux";
import {
  generateAICustomCampaignAction,
  getKonvasCampaignTemplateByIdAction,
} from "../../redux/dashboard/campaigns-slice";

import { FaArrowLeft, FaEllipsisH, FaTimes } from "react-icons/fa";
import Button from "../button";
import OutlineButton from "../outline-button";
import GeneraedCampaignDialog from "./generated-campaign-dialog";
import { convertEscapedStringToHTML } from "../../utils/functions";
import Loader from "../loader";

type PreviewModalProps = {
  isOpen: boolean;
  open: (isOpen: boolean) => void;
  templateUrl?: string;
  goBack: () => void;
  brandData: any;
  onSubmit: (payload: any) => void;
};

const PreviewModal = ({
  isOpen,
  open,
  goBack,
  brandData,
  onSubmit,
}: PreviewModalProps) => {
  const { user } = useSelector((state: any) => state.userSlice);
  const {
    isCreatingCampaign,
    isFetchingKonvasCampaignTemplateById,
    konvasTemplate,
  } = useSelector((state: any) => state.campaignsSlice);

  const dispatch = useAppDispatch();

  const [iframeSrc, setIframeSrc] = useState<string | null>(null);
  const [generatedData, setGeneratedData] = useState<any>({});
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isRegenerateModalOPen, setIsRegenerateModalOPen] = useState(false);
  const [regenerateInputQuery, setRegenerateInputQuery] = useState("");
  const [isAnotherDialogOpen, setAnotherDialogOpen] = useState(false);

  let promptText = "";

  const campaignRequest = localStorage.getItem("campaign_request");

  if (campaignRequest) promptText = campaignRequest;

  const getKonvasCampaignTemplateById = async (templateId: number) => {
    await dispatch(getKonvasCampaignTemplateByIdAction(templateId));
  };

  const handleClose = () => {
    open(false);
  };

  const handleUseTemplateClick = () => {
    setConfirmModalOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmModalOpen(false);
  };

  const handleRegenerateClose = () => {
    setIsRegenerateModalOPen(false);
  };

  const generateCampaign = async (prompt: string) => {
    const template_id = Number(localStorage.getItem("tmplt_id"));

    const payload = {
      user_query: prompt,
      company_slug: user?.company_slug,
      template_id,
    };

    const res = await dispatch(generateAICustomCampaignAction(payload));
    if (res?.success) {
      setGeneratedData(res?.data);

      setConfirmModalOpen(false);
      if (res?.data?.campaign_recipients?.length === 0) {
        const dialog = document.querySelector(
          ".preview_campaign-modal"
        ) as HTMLDialogElement;
        if (dialog) dialog.close();
        return setIsRegenerateModalOPen(true);
      }
      setAnotherDialogOpen(true);
    }

    return;
  };

  const handleCloseAnotherDialog = () => {
    setAnotherDialogOpen(false);
  };

  const handleRegenerateInputQuery = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log(e.target.value);
    setRegenerateInputQuery(e.target.value);
  };
  const handleRegenerateCampaign = () => {
    generateCampaign(regenerateInputQuery);
  };

  useEffect(() => {
    const confirmDialog = document.querySelector(
      ".confirm_modal"
    ) as HTMLDialogElement;
    if (confirmDialog) {
      if (isConfirmModalOpen) {
        confirmDialog.showModal();
      } else {
        confirmDialog.close();
      }
    }
  }, [isConfirmModalOpen]);

  useEffect(() => {
    const regenerateDialog = document.querySelector(
      ".regenerate_campaign_modal"
    ) as HTMLDialogElement;
    if (regenerateDialog) {
      if (isRegenerateModalOPen) {
        regenerateDialog.showModal();
      } else {
        regenerateDialog.close();
      }
    }
  }, [isRegenerateModalOPen]);

  useEffect(() => {
    // const htmlTemplate =  convertEscapedStringToHTML(templateUrl);

    if (konvasTemplate?.html) {
      console.log("konvasTemplate", konvasTemplate?.html);
      const htmlTemplate = convertEscapedStringToHTML(konvasTemplate?.html);
      const blob = new Blob([htmlTemplate], { type: "text/html" });
      const blobUrl = URL.createObjectURL(blob);

      setIframeSrc(blobUrl);
    }

    return () => {
      if (iframeSrc) {
        URL.revokeObjectURL(iframeSrc);
      }
    };
  }, [isOpen, konvasTemplate]);

  React.useEffect(() => {
    const templateId = Number(localStorage.getItem("tmplt_id"));

    const dialog = document.querySelector(
      ".preview_campaign-modal"
    ) as HTMLDialogElement;
    if (dialog) {
      if (isOpen) {
        dialog.showModal();
        getKonvasCampaignTemplateById(templateId);
        console.log("templateId:", templateId);
      } else {
        dialog.close();
      }
    }
  }, [isOpen]);

  return (
    <>
      <dialog className="preview_campaign-modal">
        {isFetchingKonvasCampaignTemplateById ? (
          <Loader />
        ) : konvasTemplate ? (
          <>
            <div className="template-modal_container">
              <div className="modal-header">
                <div className="preview-header">
                  <FaArrowLeft onClick={goBack} />
                  <div>
                    <h3 className="modal-title">Konvas AI Templates</h3>
                    <p>Select a template to send your campaigns</p>
                  </div>
                </div>
                <div className="action-icons">
                  <FaEllipsisH className="action-icon" />
                  <FaTimes className="close-icon" onClick={handleClose} />
                </div>
              </div>
            </div>
            <div
              className="preview-template_body"
              style={{ height: "100vh", overflow: "hidden" }}
            >
              {iframeSrc && (
                <iframe
                  src={iframeSrc}
                  title="Template Preview"
                  className="template-iframe"
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                  }}
                ></iframe>
              )}
              <div className="button_box">
                <Button
                  text="Use Template"
                  btnStyle={{ margin: "10px 0", width: "100%" }}
                  onClick={handleUseTemplateClick}
                />
                <Button
                  text="Change Template"
                  btnStyle={{
                    backgroundColor: "grey",
                    borderColor: "grey",
                    width: "100%",
                  }}
                  onClick={goBack}
                />
              </div>
              <p className="highlight-text">
                The highlighted parts contain the personalized contents.
                Personalized contents are unique to each customer.
              </p>
              <p className="template-tag-text">TEMPLATE TAGS</p>
              <hr className="divider" />
              <div className="template-tags-container">
                <div className="tags-container">
                  <p className="preview-tags-text">Simple</p>
                </div>
                <div className="tags-container">
                  <p className="preview-tags-text">Direct</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </dialog>

      <dialog className="confirm_modal">
        <div className="template-modal_container">
          <div className="modal-header">
            <div className="template-tags-container">
              <div className="tags-container">
                <p className="preview-tags-text">TEMPLATE SELECTION</p>
              </div>
            </div>
            <div className="action-icons">
              <FaEllipsisH className="action-icon" />
              <FaTimes className="close-icon" onClick={handleConfirmClose} />
            </div>
          </div>
          <div className="dialog_body">
            <p>
              A campaign message will be generated to fit the template you have
              selected.
            </p>
            <div className="dialog_buttons">
              <OutlineButton
                text="Cancel"
                onClick={handleConfirmClose}
                btnStyle={{ height: "40px" }}
              />
              <Button
                text="Confirm"
                onClick={() => generateCampaign(promptText)}
                btnStyle={{ height: "40px" }}
                loading={isCreatingCampaign}
                disabled={isCreatingCampaign}
              />
            </div>
          </div>
        </div>
      </dialog>

      <dialog className="regenerate_campaign_modal">
        <div className="template-modal_container">
          <div className="modal-header">
            <div className="template-tags-container">
              <div className="tags-container">
                <p className="preview-tags-text">Regenerate Campaign</p>
              </div>
            </div>

            <div className="action-icons">
              <FaEllipsisH className="action-icon" />
              <FaTimes className="close-icon" onClick={handleRegenerateClose} />
            </div>
          </div>
          <div className="dialog_body">
            <p className="regenerate-text">
              There are no recipients for your campaign request. Enter another
              prompt to generate a new campaign
            </p>
            <div className="regenerate_action">
              <input
                type="text"
                onChange={handleRegenerateInputQuery}
                placeholder="Enter a prompt to generate your campaign"
              />
              <Button
                text={"Generate"}
                onClick={handleRegenerateCampaign}
                loading={isCreatingCampaign}
                disabled={isCreatingCampaign}
              />
            </div>
            <div className="cancel_regenerate_button">
              <Button text="Cancel" onClick={() => open(false)} />
            </div>
          </div>
        </div>
      </dialog>

      <GeneraedCampaignDialog
        isOpen={isAnotherDialogOpen}
        generatedData={generatedData}
        brandData={brandData}
        onSubmit={onSubmit}
        onClose={handleCloseAnotherDialog}
      />
    </>
  );
};

export default PreviewModal;
