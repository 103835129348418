import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux";
import classNames from "classnames";
import ChangePassword from "./change-password";
import StoreData from "../../components/store-data";

const tabHeader = ["Store Data", "Password Update"];

const Profile = () => {
  const [activeTab, setActiveTab] = useState(tabHeader[0]);

  const renderActiveTab = () => {
    if (activeTab === "Store Data") {
      return (
        <div>
          <StoreData />
        </div>
      );
    } else if (activeTab === "Password Update") {
      return (
        <div className="password_update_section">
          <ChangePassword />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="content_container">
      <div className="ai_training">
        <div className="tab_header">
          {tabHeader.map((title: string) => (
            <div
              className={classNames("title", { active: title === activeTab })}
              key={title}
              onClick={() => setActiveTab(title)}
            >
              <p>{title}</p>
            </div>
          ))}
        </div>
        <div className="tab_content">{renderActiveTab()}</div>
      </div>
    </div>
  );
};

export default Profile;
