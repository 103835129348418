import Loader from "./loader";
import Icon from "../assets/svg";

type Props = {
    className?: string;
    btnStyle?: any;
    text: any;
    type?: any;
    leftIcon?: string;
    rightIcon?: string;
    iconColor?: string;
    iconSize?: string;
    loading?: boolean;
    loadingText?: string;
    secondaryLoader?: boolean;
    disabled?: boolean;
    onClick?: (e?: any) => void;
};

const OutlineButton = ({
    className,
    btnStyle,
    text,
    leftIcon,
    rightIcon,
    iconColor,
    iconSize,
    loading,
    loadingText,
    secondaryLoader,
    disabled,
    onClick,
    ...rest
}: Props) => (
    <button
        className={`secondary_btn btn-sec ${className ? className : "btn_secondary"} ${loading && "loading"
            }`}
        style={btnStyle}
        onClick={onClick}
        disabled={disabled || loading}
        {...rest}
    >
        {loading ? (
            <div className="loader">
                <Loader white={secondaryLoader ? false : true} />
                {loadingText && <p>{loadingText}</p>}
            </div>
        ) : (
            <>
                {leftIcon && (
                    <span className="left_icon">
                        <Icon name={leftIcon} color={iconColor} size={iconSize} />
                    </span>
                )}
                {text}
                {rightIcon && (
                    <span className="right_icon">
                        <Icon name={rightIcon} color={iconColor} size={iconSize} />
                    </span>
                )}
            </>
        )}
    </button>
);

export default OutlineButton;
